import { useState, useEffect } from 'react';

import './style.scss';

export interface HeroInterface {
  colorTheme: string;
  title: string | React.ReactNode;
  colorTitle?: string;
  description: string | React.ReactNode;
  colorDescription?: string;
  image: React.ReactNode;
  isHome?: boolean;
}

export function Hero({ colorTheme, title, colorTitle, description, colorDescription, image, isHome }: HeroInterface) {
  useEffect(() => {}, []);

  return (
    <section
      className={`hero w-full bg-rich-black-2 ${isHome ? 'is-home' : ''}`}
      style={{ '--color-theme': colorTheme || '' } as React.CSSProperties}
    >
      <div className="hero-center flex justify-between relative">
        <div className="meta lg:w-1/2 lg:pt-[100px]">
          {!!isHome ? (
            <>
              <div className="title">
                <h1 className={`${colorTitle?.length ? `text-[${colorTitle}]` : 'text-rich-black'} leading-none`}>{title}</h1>
                <span className="year">2019-2022</span>
              </div>
            </>
          ) : (
            <>
              <h1 className={`${colorTitle?.length ? `text-[${colorTitle}]` : 'text-rich-black'} leading-none`}>{title}</h1>
            </>
          )}

          <div className="description flex justify-end lg:pt-8">
            <p>{description}</p>
          </div>
        </div>
        <div className="image overflow-hidden lg:w-1/2">
          <span className="line" />
          <div className="cover">{image}</div>
        </div>
      </div>
    </section>
  );
}
