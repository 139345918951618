import './style.scss';

export interface AnchorInterface {
  href: string;
  className?: string;
  children: string | React.ReactNode;
  [key: string]: any;
}

export function Anchor({ href, className, children, ...rest }: AnchorInterface) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`break-words lg:break-normal text-absolute-zero-crayola hover:text-absolute-zero-crayola underline hover:no-underline ${
        className || ''
      }`}
      {...rest}
    >
      {children}
    </a>
  );
}
