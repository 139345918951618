import { useMemo } from 'react';

import { useBasic } from 'contexts';
import { Anchor, ContentBox, FlexibleBox, LinkButton, Hero, NucleosDeAtuacao, TitleArea } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-branding.jpg';

export default function BrandingPage() {
  const { width } = useBasic();
  const colorTheme = '#6FBFBE';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Branding"
        description="Responsável pela Coordenação Nacional do Programa de Gestão da Marca Sebrae, cuidando das estratégias e repertórios da marca, assim como do seu monitoramento, sendo guardiã da marca."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-8 lg:pt-16 space-y-8 lg:space-y-16" colorTheme={colorTheme} watermark>
        <FlexibleBox colorTheme={colorTheme} title={<>Valor da Marca Sebrae</>} position={'center'}>
          <p>O projeto de branding desenvolveu estratégias eficazes de posicionamento de marca, e o reconhecimento é notório.</p>

          <div className="w-box-full space-y-8 lg:space-y-12 pt-8 lg:pt-12">
            <div className="flex items-center justify-center space-x-2 lg:space-x-4">
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2009
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 1,4 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  -
                </span>
              </div>
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2011
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 1,6 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  +15%
                </span>
              </div>
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2012
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 2,4 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  +50%
                </span>
              </div>
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2013
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 4,7 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  +95%
                </span>
              </div>
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2017
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 3,2 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  -32%
                </span>
              </div>
              <div className="bg-anti-flash-white">
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-2xl leading-none font-normal">
                  2021
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[49px] bg-picton-blue flex items-center justify-center rounded-lg font-campuni-medium text-[9px] lg:text-lg leading-none font-normal">
                  R$ 7,6 bi
                </span>
                <span className="w-[44px] lg:w-[110px] h-[25px] lg:h-[45px] flex items-center justify-center font-campuni-medium text-[9px] lg:text-base leading-none font-normal">
                  +138%
                </span>
              </div>
            </div>

            <div className="table-branding lg:pl-[43px] w-full lg:max-w-[748px] relative">
              <p className="w-[191px] lg:w-[684px] ml-[45px] lg:ml-auto lg:mx-auto mb-[48px] block text-base lg:text-xl font-campuni-bold font-normal">
                Considerando-se a taxa Real US$ de 5,19 - 31/12/2020 - nossa marca valeria US$ 1.42 Bi. Se estivesse no ranking de
                2020 da BrandZ, ocuparia a décima terceira posição.
              </p>

              <div className="w-full">
                <table cellPadding={0} cellSpacing={0} border={0} className="w-full">
                  <thead>
                    <tr>
                      <th colSpan={4}>
                        RANKING DAS 15 MARCAS MAIS VALIOSAS <br />
                        DO BRASIL (em US$ milhões)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>RANKING</td>
                      <td>MARCA</td>
                      <td>
                        VALOR DA MARCA <br />
                        2020
                      </td>
                      <td>
                        VARIAÇÃO DO <br />
                        VALOR DA MARCA
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>ITAÚ</td>
                      <td>8.268</td>
                      <td>-1%</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Skol</td>
                      <td>6.797</td>
                      <td>-6%</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>BRADESCO</td>
                      <td>6.137</td>
                      <td>-35%</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>MAGAZINE LUIZA</td>
                      <td>5.111</td>
                      <td>124%</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>BRAHMA</td>
                      <td>3.720</td>
                      <td>-2%</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>GLOBO</td>
                      <td>3.295</td>
                      <td>-9%</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>ANTARCTICA</td>
                      <td>2.558</td>
                      <td>-4%</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>RENNER</td>
                      <td>2.273</td>
                      <td>19%</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>AMIL</td>
                      <td>2.050</td>
                      <td>11%</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>SADIA</td>
                      <td>1.637</td>
                      <td>22%</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>YPÊ</td>
                      <td>1.570</td>
                      <td>13%</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>NATURA</td>
                      <td>1.516</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>PÃO DE AÇÚCAR</td>
                      <td>1.299</td>
                      <td>187%</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>LOJAS AMERICANAS</td>
                      <td>1.282</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>LOCALIZA</td>
                      <td>1.268</td>
                      <td>72%</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="fonte">Fonte: Ranking BrandZ Top 25 Brasil, da Kanter e do WPP</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>8ª marca mais forte do país</>} position={'start'}>
          <p className="mb-12">
            Segundo estudo realizado pela ISTOÉ Dinheiro, com desenvolvimento da TM20, BAV, WPP e Superunion, o Sebrae parte das
            10 marcas mais fortes do país. Confira a pontuação:
          </p>

          <ol className="list-decimal list-inside">
            <li>Nubank - 100</li>
            <li>Tramontina - 99</li>
            <li>iFood - 98</li>
            <li>O Boticário - 98</li>
            <li>Americanas - 98</li>
            <li>Magazine Luiza - 97</li>
            <li>Natura - 97</li>
            <li>
              <strong className="font-campuni-bold font-normal">Sebrae - 97</strong>
            </li>
            <li>Cacau Show - 97</li>
            <li>Brastemp - 95</li>
          </ol>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Pesquisa de imagem</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Indicadores do Programa de gestão da marca Sebrae:</strong>
              </p>
              <p>
                Imagem junto aos pequenos negócios: <br />
                8,7 (2020) 8,6 (2021) 8,7 (2022) <br />
                Imagem junto à sociedade: <br />
                8,1 (2020) 8,4 (2021) 8,4 (2022)
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>AJamile Sales</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://datasebrae.com.br/wp-content/uploads/2020/02/Gest%C3%A3o-da-Marca-Sebrae.pdf'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://datasebrae.com.br/wp-content/uploads/2020/02/Gest%C3%A3o-da-Marca-Sebrae.pdf'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Estruturação do Programa de Gestão da Marca Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2020-2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Foi instituído com o objetivo de promover a imagem institucional do Sebrae por meio dodesenvolvimento e da
                disseminação de estratégias de gestão de marca (branding) paranortear a comunicação de todo o sistema.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Orçamento previsto:</strong>
              </p>
              <p>R$ 47.260.921,00</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Executado:</strong>
              </p>
              <p>R$ 45.719.471,13</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Adesão de todos os estados!</strong>
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jamile Sales</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://datasebrae.com.br/wp-content/uploads/2020/02/Gest%C3%A3o-da-Marca-Sebrae.pdf'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://datasebrae.com.br/wp-content/uploads/2020/02/Gest%C3%A3o-da-Marca-Sebrae.pdf'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Brandbook estruturado</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out/20-jul/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Elaboração do Brandbook da Marca Sebrae, documento técnico que norteia o Programa deGestão da Marca Sebrae,
                reunido estratégias e repertórios de branding
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>Resultado: brandbook estruturado</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jamile Sales</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>TR de branding</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>01 a 10/2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                O termo de referência é o documento para licitação com o objetivo de contratar umaagência de branding, a qual
                atenderá às demandas do Programa de Gestão da Marca.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>Estruturação de termo de referência de branding</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jamile Sales</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Sistema de gestão de tickets referentes ao monitoramento da marca com foco nouso indevido</>}
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Mai-ago/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Um novo sistema foi customizado para atender a essa demanda, da qual também fazemparte treinamento junto aos
                interlocutores nas UF, documento com processos mapeados e videotutorial para uso do sistema. O monitoramento faz
                varreduras para encontrar casos deuso indevido da marca Sebrae, incluindo tentativas de golpe, e é seguido de
                providências nosentido de sanar e coibir tais usos.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                Estruturação de nova organização para distribuição de tickets de atendimento referentes aomonitoramento da marca,
                com foco no uso indevido
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jamile Sales</p>
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
