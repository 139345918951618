import { useMemo } from 'react';

import { useBasic } from 'contexts';
import { ContentBox, Hero, NucleosDeAtuacao, TitleArea } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-administrativo.jpg';
import ImageTable from 'assets/images/administrativo/orcamento.png';

export default function AdministrativoPage() {
  const { width } = useBasic();
  const colorTheme = 'linear-gradient(180deg, #006EC7 -6.58%, #005BA5 100%)';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Administrativo"
        description="Responsável por apoiar a gestão dos serviços contratados pela Unidade de Comunicação, garantindo a eficiência e eficácia das operações."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-4 lg:pt-14 !pb-[48px] lg:!pb-[128px]">
        <TitleArea className="!pb-4 lg:!pb-16 !pt-0 !px-0 w-full max-w-[1030px] mx-auto block">Orçamento</TitleArea>
        <p className="block w-full max-w-[1030px] mx-auto text-center lg:text-left text-2xl lg:text-[28px] leading-snug text-black mb-8 lg:mb-32">
          <strong className="font-campuni-bold font-normal">
            Em quatro anos, a UCOM conseguiu identificar oportunidades que resultaram em redução de custos e aumento de
            eficiência.
          </strong>
        </p>

        <div className="block">
          <img src={ImageTable} alt=" " className="max-w-[1080px] w-full mx-auto block" />
        </div>

        {width < 1024 ? (
          <a
            href={`${process.env.PUBLIC_URL}/img/orcamento.png`}
            target="_blank"
            rel="noopener noreferrer"
            className={`link-download-orcamento rounded-md p-4 leading-none inline-flex items-center font-campuni-bold font-normal text-lg`}
            download
          >
            Faça o download do orçamento gratuito
          </a>
        ) : null}
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
