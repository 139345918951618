import { useState, useEffect } from 'react';
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from 'react-icons/bs';

import './style.scss';
import LogoSebrae from 'assets/images/logo-sebrae.svg';

export function Footer() {
  useEffect(() => {}, []);

  return (
    <footer className="flex flex-col lg:flex-row items-center justify-between bg-black-1 pl-8 lg:pl-6 py-8 lg:py-2 pr-8 lg:pr-9 space-y-8 lg:space-y-0">
      <a href="https://sebrae.com.br" target="_blank" rel="noopener noreferrer" className="block" title="Portal Sebrae">
        <img src={LogoSebrae} alt="Sebrae" className="block m-0 p-0 w-auto h-[40.29px]" />
      </a>

      <ul className="flex items-center justify-end space-x-4 text-white-1 text-3xl">
        <li>
          <a href="https://www.facebook.com/sebrae" target="_blank" rel="noopener noreferrer" className="block" title="Facebook">
            <BsFacebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/sebrae/"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
            title="Instagram"
          >
            <BsInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/user/tvsebrae"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
            title="Youtube"
          >
            <BsYoutube />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/sebrae/"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
            title="Linkedin"
          >
            <BsLinkedin />
          </a>
        </li>
      </ul>
    </footer>
  );
}
