import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import PATHS, { pathsInterface } from './paths';
import NotFoundPage from 'pages/NotFound';

export default function RoutesLayout() {
  const routeTree = (objRoute: pathsInterface[]) =>
    objRoute.map(route => (
      <Route key={route.id} path={route.pathname} element={<Outlet />}>
        <Route index element={route.component} />
        {route.children?.length ? routeTree(route.children) : null}
      </Route>
    ));

  return (
    <>
      <Routes>
        {!!PATHS.length && routeTree(PATHS)}

        <Route
          path="*"
          element={
            <Suspense fallback={<div />}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}
