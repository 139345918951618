import { useEffect, useMemo } from 'react';

import { useBasic } from 'contexts';
import { Hero, ContentBox, FlexibleBox, TitleArea, LinkButton, NucleosDeAtuacao, Anchor } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-conteudo.jpg';
import ImagePSJ from 'assets/images/pagina-conteudo/foto-psj.jpg';
import ImageAssessoria from 'assets/images/pagina-conteudo/celular-assessoria.png';
import ImageNotebook1 from 'assets/images/pagina-conteudo/parceria-notebook-1.png';
import ImageNotebook2 from 'assets/images/pagina-conteudo/parceria-notebook-2.png';
import ImageSite from 'assets/images/pagina-conteudo/canal-empreender.jpg';

export default function ConteudoPage() {
  const { width } = useBasic();
  const colorTheme = '#9285F9';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Conteúdo"
        description={
          <>
            As informações são apuradas e checadas, permitindo a produção de conteúdo para os veículos de comunicação nacionais,
            notícias para a Agência Sebrae de Notícias e pautas para o <br />
            Canal Empreender, assessoria de imprensa e comunicação interna.
          </>
        }
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="lg:pt-4 space-y-8 lg:space-y-16" colorTheme={colorTheme} watermark>
        <TitleArea>Imprensa</TitleArea>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Alcance na Mídia Espontânea <br />
              2019 a 2022
            </>
          }
          position={'center'}
          className="!mt-0 lg:!mt-[4rem]"
        >
          <div className="space-y-6 lg:space-y-12">
            <p className="text-dark-charcoal">
              <strong className="font-campuni-bold font-normal">
                A visibilidade positiva gerada pela assessoria de imprensa faz com que a credibilidade da marca aumente e o
                relacionamento com o público seja fortalecido.
              </strong>
            </p>

            <div className="w-box-full flex items-center justify-center space-x-4 lg:space-x-8">
              <div className="bg-anti-flash-white">
                <span className="h-[27px] lg:h-[43px] w-[60px] lg:w-[105px] flex items-center justify-center font-campuni-medium text-xs lg:text-2xl leading-none font-normal">
                  2019
                </span>
                <span className="bg-picton-blue flex items-center justify-center rounded lg:rounded-lg w-[60px] lg:w-[105px] h-[27px] lg:h-[46px] font-campuni-medium text-xs lg:text-xl leading-none font-normal">
                  8,5 mil
                </span>
              </div>

              <div className="bg-anti-flash-white">
                <span className="h-[27px] lg:h-[43px] w-[60px] lg:w-[105px] flex items-center justify-center font-campuni-medium text-xs lg:text-2xl leading-none font-normal">
                  2020
                </span>
                <span className="bg-picton-blue flex items-center justify-center rounded lg:rounded-lg w-[60px] lg:w-[105px] h-[27px] lg:h-[46px] font-campuni-medium text-xs lg:text-xl leading-none font-normal">
                  9 mil
                </span>
              </div>

              <div className="bg-anti-flash-white">
                <span className="h-[27px] lg:h-[43px] w-[60px] lg:w-[105px] flex items-center justify-center font-campuni-medium text-xs lg:text-2xl leading-none font-normal">
                  2021
                </span>
                <span className="bg-picton-blue flex items-center justify-center rounded lg:rounded-lg w-[60px] lg:w-[105px] h-[27px] lg:h-[46px] font-campuni-medium text-xs lg:text-xl leading-none font-normal">
                  9 mil
                </span>
              </div>

              <div className="bg-anti-flash-white">
                <span className="h-[27px] lg:h-[43px] w-[60px] lg:w-[105px] flex items-center justify-center font-campuni-medium text-xs lg:text-2xl leading-none font-normal">
                  2022
                </span>
                <span className="bg-picton-blue flex items-center justify-center rounded lg:rounded-lg w-[60px] lg:w-[105px] h-[27px] lg:h-[46px] font-campuni-medium text-xs lg:text-xl leading-none font-normal">
                  10,1 mil
                </span>
              </div>
            </div>

            <ul>
              <li>
                + de <strong className="font-campuni-bold font-normal">36 mil notícias</strong> na grande imprensa
              </li>
              <li>
                + de <strong className="font-campuni-bold font-normal">60 matérias/mês</strong> (informação a seguir menor e
                embaixo) Globo, Valor, Record, Band e outros
              </li>
              <li>
                + de <strong className="font-campuni-bold font-normal">97% de exposição positiva</strong>
              </li>
              <li>
                + de <strong className="font-campuni-bold font-normal">25 Jornais Nacionais</strong>
              </li>
            </ul>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Fotoware</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Dez/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                O Fotoware é um acervo digital que permite o gerenciamento de fotografias, gráficos, vídeos, áudios, documentos e
                demais arquivos digitais, além de contar com uma ferramenta de busca rápida, interativa e precisa
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Natasha Araujo</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Manual com mapeamento de crises e <br />
              protocolo de ações
            </>
          }
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jun-nov/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Com base em informações coletadas com 25 técnicos e lideranças do Sebrae Nacional e da Abase, o Comitê de
                Reputação da Marca para 2022 estruturou o manual com mapeamento das crises enfrentadas pela instituição nos
                últimos cinco anos e preparou uma proposta de protocolo de ações para gerenciá-las.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Andréa Sekeff, Ana Canedo, Tatiana Mendonça. e Jamile Sales</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width < 1024 ? (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://drive.google.com/file/d/1etCYdKmO34tfN4eDHajSeNWIhA5WCNbG/view'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </div>
              ) : (
                <LinkButton
                  colorTheme={colorTheme}
                  url={'https://drive.google.com/file/d/1etCYdKmO34tfN4eDHajSeNWIhA5WCNbG/view'}
                >
                  Faça o download do PDF
                </LinkButton>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Mudança na metodologia do <br />
              relacionamento com a imprensa
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jun-ago/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                O relacionamento com a imprensa passou a ser operacionalizado pela equipe de assessoria de imprensa do Sebrae, não
                mais pela empresa contratada. Com a mudança, as pautas passaram a ser ofertadas à imprensa de forma estratégica.
                Com a nova metodologia, o alcance da mídia espontânea passou de 96 para 250 veículos de circulação nacional, no
                período de 2019 até junho de 2022.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Prêmio Sebrae de Jornalismo</>} position={'end'}>
          <div className="w-box-full flex items-center justify-center mb-[60px]">
            <svg width="64" height="97" viewBox="0 0 64 97" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.0644531 96.4127L4.87224 74.5877C8.18597 74.2631 11.2698 73.4803 14.1239 72.22C16.9587 70.9789 19.3531 69.4131 21.2877 67.5037C23.2223 65.6133 24.8696 63.4938 26.2487 61.1834C27.6087 58.873 28.5664 56.3907 29.1027 53.7366H7.20909L16.5757 11.3468H60.8609L52.2797 51.0251C50.4792 59.7895 47.0505 67.4846 41.9746 74.0913C36.8986 80.7171 30.75 85.8726 23.5671 89.596C16.365 93.3194 8.53075 95.5917 0.0644531 96.4127Z"
                fill="#0024A9"
              />
              <path d="M61.4164 7.98665L63.0253 0.539795H18.7401L17.0928 7.98665H61.4164Z" fill="#0024A9" />
            </svg>
          </div>

          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Edições 2021 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                O Prêmio Sebrae de Jornalismo é uma forma de relacionamento com a imprensa que permite que os trabalhos dos
                profissionais sejam reconhecidos pelo Sebrae. O projeto havia parado em 2015 e retornou em 2020, em sua 8º edição,
                com adesão significativa dos estados. Apenas cinco estados não aderiram (AC, AP, MT, PB, RS e RO).
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width < 1024 ? (
                <div className="w-box-full flex items-center justify-center">
                  <img src={ImagePSJ} alt=" " className="mx-auto block w-[100%]" />
                </div>
              ) : (
                <img src={ImagePSJ} alt=" " className="mx-auto block w-[365px]" />
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Agência Sebrae de Notícias <br />
              (ASN)
            </>
          }
          position={'start'}
        >
          <div className="w-box-full flex items-center justify-center mb-[60px]">
            <svg width="193" height="100" viewBox="0 0 193 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M26.1631 70.0342H13.7541L11.7442 79.5162H0.733398L14.5406 19.6659H25.3766L39.1838 79.5162H28.0856L26.1631 70.0342ZM24.2405 60.6281L19.9586 39.2367L15.5892 60.7039H24.2405V60.6281Z"
                fill="#0024A9"
              />
              <path
                d="M59.1953 80.2748C54.4764 80.2748 50.6314 79.137 47.7476 76.7855C44.4269 74.0546 42.8539 70.3377 42.8539 65.5588V57.7456H53.6899V65.5588C53.6899 67.6069 54.2142 69.0481 55.1755 69.8067C56.0494 70.4894 57.3602 70.8687 59.1953 70.8687C61.1178 70.8687 62.4286 70.5653 63.2151 69.8067C64.1764 69.0481 64.7007 67.6069 64.7007 65.5588V61.766C64.7007 59.111 63.4773 56.8353 61.0304 54.9389C60.5061 54.5597 59.1079 53.8011 56.9232 52.7391C53.8647 51.2979 51.5926 50.0842 50.107 48.9463C47.7476 47.1258 45.9124 45.0777 44.689 42.7262C43.4656 40.3746 42.8539 37.8714 42.8539 35.2164V33.7752C42.8539 29.0721 44.5143 25.2793 47.7476 22.5485C50.6314 20.197 54.4764 19.0591 59.1953 19.0591C63.9142 19.0591 67.7592 20.197 70.643 22.5485C73.9637 25.2793 75.5367 28.9962 75.5367 33.7752V39.0092H64.7007V33.7752C64.7007 31.6512 64.1764 30.2099 63.2151 29.5272C62.4286 28.8445 61.1178 28.4652 59.1953 28.4652C57.3602 28.4652 56.0494 28.8445 55.1755 29.5272C54.2142 30.3616 53.6899 31.727 53.6899 33.7752V35.2164C53.6899 37.8714 54.9133 40.0712 57.3602 41.9676C58.0593 42.4227 59.2827 43.1054 61.2052 44.0157C64.6133 45.6087 66.9728 46.8982 68.2836 47.9602C73.1772 51.753 75.5367 56.3044 75.5367 61.8418V65.6346C75.5367 70.3377 73.8763 74.1305 70.643 76.8613C67.7592 79.0611 63.9142 80.2748 59.1953 80.2748Z"
                fill="#0024A9"
              />
              <path
                d="M115.123 79.5163H108.832L92.3154 49.1739V79.5163H81.4794V19.7418H87.7712L104.287 50.0083V19.7418H115.123V79.5163Z"
                fill="#0024A9"
              />
              <path
                d="M129.191 30.2098H124.909L124.035 33.6992H123.161L126.569 20.3485H127.356L130.764 33.6992H129.89L129.191 30.2098ZM128.928 29.5271L127.006 21.8657L125.083 29.5271H128.928Z"
                fill="#0024A9"
              />
              <path
                d="M134.958 33.9267C133.997 33.9267 133.298 33.6991 132.774 33.244C132.162 32.713 131.812 32.0303 131.812 31.0442V23.0793C131.812 22.0932 132.162 21.4105 132.774 20.8795C133.298 20.4244 134.084 20.1968 134.958 20.1968C135.92 20.1968 136.619 20.4244 137.143 20.8795C137.755 21.4105 138.104 22.0932 138.104 23.0793V24.3689H137.318V23.0793C137.318 22.3208 137.056 21.7898 136.619 21.4105C136.182 21.1071 135.657 20.9554 134.958 20.9554C134.259 20.9554 133.735 21.1071 133.298 21.4105C132.861 21.7898 132.599 22.3208 132.599 23.0793V31.0442C132.599 31.8027 132.861 32.3337 133.298 32.713C133.648 33.0164 134.259 33.1682 134.958 33.1682C135.657 33.1682 136.269 33.0164 136.619 32.713C137.056 32.3337 137.318 31.8027 137.318 31.0442V27.4031H134.958V26.7204H138.104V31.0442C138.104 32.0303 137.755 32.713 137.143 33.244C136.706 33.6991 135.92 33.9267 134.958 33.9267Z"
                fill="#0024A9"
              />
              <path
                d="M140.551 26.5687H143.872V27.2514H140.551V33.0164H145.27V33.6991H139.765V20.3485H145.27V21.0312H140.551V26.5687ZM143.086 17.7694L144.571 19.6658H143.959L142.561 18.3004L141.163 19.6658H140.551L142.037 17.7694H143.086Z"
                fill="#0024A9"
              />
              <path
                d="M153.398 33.6992H152.961L147.717 22.8518V33.6992H146.931V20.3485H147.368L152.611 31.1959V20.3485H153.398V33.6992Z"
                fill="#0024A9"
              />
              <path
                d="M158.289 33.9267C157.328 33.9267 156.629 33.6991 156.104 33.244C155.493 32.713 155.143 32.0303 155.143 31.0442V23.0793C155.143 22.0932 155.493 21.4105 156.104 20.8795C156.629 20.4244 157.415 20.1968 158.289 20.1968C159.25 20.1968 159.95 20.4244 160.474 20.8795C161.086 21.4105 161.435 22.0932 161.435 23.0793V24.3689H160.649V23.0793C160.649 22.3208 160.386 21.7898 159.95 21.4105C159.6 21.1071 158.988 20.9554 158.289 20.9554C157.59 20.9554 156.978 21.1071 156.629 21.4105C156.192 21.7898 155.93 22.3208 155.93 23.0793V31.0442C155.93 31.8027 156.192 32.3337 156.629 32.713C156.978 33.0164 157.59 33.1682 158.289 33.1682C158.988 33.1682 159.6 33.0164 159.95 32.713C160.386 32.3337 160.649 31.8027 160.649 31.0442V29.1478H161.435V31.0442C161.435 32.0303 161.086 32.713 160.474 33.244C159.95 33.6991 159.25 33.9267 158.289 33.9267Z"
                fill="#0024A9"
              />
              <path d="M163.972 33.6992H163.185V20.3485H163.972V33.6992Z" fill="#0024A9" />
              <path
                d="M171.311 30.2098H167.029L166.155 33.6992H165.281L168.69 20.3485H169.476L172.884 33.6992H172.01L171.311 30.2098ZM171.049 29.5271L169.126 21.8657L167.204 29.5271H171.049Z"
                fill="#0024A9"
              />
              <path
                d="M127.006 56.7594C125.958 56.7594 125.084 56.5318 124.472 56.0008C123.773 55.394 123.336 54.5596 123.336 53.4976V51.7529H125.783V53.4976C125.783 53.9527 125.87 54.2561 126.132 54.4837C126.307 54.6354 126.657 54.7113 127.006 54.7113C127.443 54.7113 127.705 54.6354 127.88 54.4837C128.142 54.332 128.23 54.0286 128.23 53.4976V52.6632C128.23 52.0563 127.968 51.6012 127.443 51.146C127.356 51.0702 127.006 50.9185 126.482 50.6151C125.783 50.3116 125.259 50.0082 124.996 49.7806C124.472 49.4014 124.035 48.9462 123.773 48.4152C123.511 47.8842 123.336 47.3532 123.336 46.7464V46.443C123.336 45.381 123.686 44.5466 124.472 43.9397C125.084 43.4087 125.958 43.1812 127.006 43.1812C128.055 43.1812 128.929 43.4087 129.54 43.9397C130.24 44.5466 130.677 45.381 130.677 46.443V47.5808H128.23V46.443C128.23 45.9878 128.142 45.6844 127.88 45.4569C127.705 45.3051 127.443 45.2293 127.006 45.2293C126.569 45.2293 126.307 45.3051 126.132 45.4569C125.87 45.6086 125.783 45.9878 125.783 46.443V46.7464C125.783 47.3532 126.045 47.8084 126.569 48.2635C126.744 48.3394 127.006 48.4911 127.443 48.7187C128.23 49.0979 128.754 49.4014 129.016 49.6289C130.065 50.4633 130.677 51.5253 130.677 52.739V53.5734C130.677 54.6354 130.327 55.4698 129.54 56.0767C128.929 56.5318 128.055 56.7594 127.006 56.7594Z"
                fill="#0024A9"
              />
              <path
                d="M134.521 45.381V48.7186H137.23V50.8426H134.521V54.5595H138.453V56.6835H132.074V43.257H138.453V45.381H134.521Z"
                fill="#0024A9"
              />
              <path
                d="M143.61 56.6076H139.939V43.257H143.26C144.309 43.257 145.183 43.4846 145.794 44.0156C146.144 44.319 146.493 44.6983 146.668 45.0775C146.843 45.4568 146.93 45.9878 146.93 46.5188V47.7325C146.93 48.6428 146.668 49.3255 146.056 49.7806C146.843 50.3116 147.28 51.0701 147.28 52.208V53.4217C147.28 53.9527 147.192 54.4078 147.018 54.7871C146.843 55.2422 146.581 55.5457 146.144 55.8491C145.445 56.3801 144.658 56.6076 143.61 56.6076ZM143.26 48.8703C143.697 48.8703 143.959 48.7945 144.134 48.6428C144.396 48.4152 144.484 48.1118 144.484 47.6566V46.4429C144.484 45.9878 144.396 45.6844 144.134 45.5327C143.959 45.381 143.61 45.3051 143.173 45.3051H142.299V48.8703H143.26ZM142.386 54.4837H143.61C144.047 54.4837 144.396 54.4078 144.571 54.2561C144.746 54.1044 144.92 53.801 144.92 53.3458V52.1321C144.92 51.677 144.833 51.3736 144.571 51.146C144.396 50.9943 144.047 50.9184 143.697 50.9184H142.474V54.4837H142.386Z"
                fill="#0024A9"
              />
              <path
                d="M155.931 56.6076H153.484L152.26 51.2219H151.124V56.6076H148.678V43.257H152.348C153.396 43.257 154.27 43.5604 154.882 44.0914C155.581 44.6983 156.018 45.5327 156.018 46.5947V48.0359C156.018 49.0979 155.669 49.9323 154.882 50.5392C154.795 50.615 154.62 50.6909 154.533 50.7667L155.931 56.6076ZM153.571 46.5188C153.571 46.0637 153.484 45.7602 153.222 45.5327C153.047 45.381 152.697 45.3051 152.348 45.3051H151.124V49.0979H152.348C152.785 49.0979 153.047 49.022 153.222 48.8703C153.484 48.7186 153.571 48.3393 153.571 47.8842V46.5188Z"
                fill="#0024A9"
              />
              <path
                d="M162.484 54.4837H159.775L159.338 56.6076H156.892L159.95 43.257H162.397L165.456 56.6076H163.009L162.484 54.4837ZM162.135 52.3597L161.174 47.5808L160.212 52.3597H162.135Z"
                fill="#0024A9"
              />
              <path
                d="M169.04 45.381V48.7186H171.749V50.8426H169.04V54.5595H172.973V56.6835H166.593V43.257H172.973V45.381H169.04Z"
                fill="#0024A9"
              />
              <path
                d="M126.83 79.5161H123.684V66.1655H126.83C127.791 66.1655 128.49 66.3931 129.015 66.8482C129.626 67.3792 129.976 68.0619 129.976 69.048V76.7853C129.976 77.7715 129.626 78.4542 129.015 78.9851C128.49 79.2886 127.791 79.5161 126.83 79.5161ZM124.558 78.7576H126.917C128.49 78.7576 129.277 78.0749 129.277 76.7095V68.9722C129.277 68.2136 129.015 67.6826 128.578 67.3033C128.141 66.9999 127.616 66.8482 126.917 66.8482H124.558V78.7576Z"
                fill="#0024A9"
              />
              <path
                d="M132.336 66.8482V72.3098H135.657V72.9925H132.336V78.7576H137.055V79.4403H131.55V66.1655H137.055V66.8482H132.336Z"
                fill="#0024A9"
              />
              <path
                d="M148.503 79.5161H148.066L142.822 68.6687V79.5161H142.036V66.1655H142.473L147.716 77.0129V66.1655H148.503V79.5161Z"
                fill="#0024A9"
              />
              <path
                d="M153.395 79.6679C152.434 79.6679 151.735 79.4403 151.211 78.9852C150.599 78.4542 150.249 77.7715 150.249 76.7853V68.8205C150.249 67.8343 150.599 67.1516 151.211 66.6206C151.735 66.1655 152.521 65.9379 153.395 65.9379C154.357 65.9379 155.056 66.1655 155.58 66.6206C156.192 67.1516 156.541 67.8343 156.541 68.8205V76.7853C156.541 77.7715 156.192 78.4542 155.58 78.9852C155.056 79.4403 154.357 79.6679 153.395 79.6679ZM153.395 66.6965C152.696 66.6965 152.172 66.8482 151.735 67.1516C151.298 67.5309 151.036 68.0619 151.036 68.8205V76.7853C151.036 77.5439 151.298 78.0749 151.735 78.4542C152.085 78.7576 152.696 78.9093 153.395 78.9093C154.094 78.9093 154.706 78.7576 155.056 78.4542C155.493 78.0749 155.755 77.5439 155.755 76.7853V68.8205C155.755 68.0619 155.493 67.5309 155.056 67.1516C154.706 66.8482 154.094 66.6965 153.395 66.6965Z"
                fill="#0024A9"
              />
              <path d="M161.175 66.8482V79.5161H160.388V66.8482H157.504V66.1655H164.146V66.8482H161.175Z" fill="#0024A9" />
              <path
                d="M166.068 79.5162H165.281V66.1656H166.068V79.5162ZM166.854 63.5865L165.369 65.4829H165.981L167.903 63.5865H166.854Z"
                fill="#0024A9"
              />
              <path
                d="M170.962 79.6679C170.001 79.6679 169.301 79.4403 168.777 78.9852C168.165 78.4542 167.816 77.7715 167.816 76.7853V68.8205C167.816 67.8343 168.165 67.1516 168.777 66.6206C169.301 66.1655 170.088 65.9379 170.962 65.9379C171.923 65.9379 172.622 66.1655 173.146 66.6206C173.758 67.1516 174.108 67.8343 174.108 68.8205V70.11H173.321V68.8205C173.321 68.0619 173.059 67.5309 172.622 67.1516C172.273 66.8482 171.661 66.6965 170.962 66.6965C170.263 66.6965 169.651 66.8482 169.301 67.1516C168.865 67.5309 168.602 68.0619 168.602 68.8205V76.7853C168.602 77.5439 168.865 78.0749 169.301 78.4542C169.651 78.7576 170.263 78.9093 170.962 78.9093C171.661 78.9093 172.273 78.7576 172.622 78.4542C173.059 78.0749 173.321 77.5439 173.321 76.7853V74.8889H174.108V76.7853C174.108 77.7715 173.758 78.4542 173.146 78.9852C172.622 79.4403 171.923 79.6679 170.962 79.6679Z"
                fill="#0024A9"
              />
              <path d="M176.642 79.5161H175.856V66.1655H176.642V79.5161Z" fill="#0024A9" />
              <path
                d="M183.982 75.951H179.7L178.826 79.4403H177.952L181.36 66.0897H182.147L185.555 79.4403H184.681L183.982 75.951ZM183.72 75.2682L181.797 67.6068L179.875 75.2682H183.72Z"
                fill="#0024A9"
              />
              <path
                d="M189.837 79.6679C188.876 79.6679 188.177 79.4403 187.652 78.9852C187.041 78.4542 186.691 77.7715 186.691 76.7853V74.8889H187.478V76.8612C187.478 77.6197 187.74 78.1507 188.177 78.53C188.526 78.8334 189.138 78.9852 189.837 78.9852C190.536 78.9852 191.061 78.8334 191.497 78.53C191.934 78.1507 192.197 77.6197 192.197 76.8612V75.9509C192.197 75.0406 191.934 74.3579 191.323 73.9028C190.798 73.4477 190.187 73.1442 189.662 72.9167C188.963 72.6891 188.352 72.3098 187.652 71.7788C186.953 71.2479 186.604 70.4134 186.604 69.2756V68.8205C186.604 67.8343 186.953 67.1516 187.565 66.6206C188.089 66.1655 188.876 65.9379 189.75 65.9379C190.711 65.9379 191.41 66.1655 191.934 66.6206C192.546 67.1516 192.896 67.8343 192.896 68.8205V70.11H192.109V68.8205C192.109 68.0619 191.847 67.5309 191.41 67.1516C191.061 66.8482 190.536 66.6965 189.75 66.6965C188.963 66.6965 188.439 66.8482 188.089 67.1516C187.652 67.5309 187.39 68.0619 187.39 68.8205V69.1997C187.39 69.8824 187.565 70.4134 187.915 70.7927C188.264 71.2478 188.614 71.4754 188.876 71.703C189.225 71.8547 189.575 72.0064 189.925 72.1581C191.934 72.9167 192.896 74.1304 192.896 75.8751V76.7853C192.896 77.7715 192.546 78.4542 191.934 78.9852C191.497 79.4403 190.798 79.6679 189.837 79.6679Z"
                fill="#0024A9"
              />
              <path
                d="M24.0658 14.3561C28.8721 9.80471 35.6009 7.22561 42.8541 7.22561C50.1072 7.22561 56.836 9.88057 61.6423 14.3561H71.0801C65.0504 5.70849 54.4766 0.550293 42.8541 0.550293C31.2316 0.550293 20.6577 5.70849 14.628 14.3561H24.0658Z"
                fill="#0024A9"
              />
              <path
                d="M105.684 85.5848C100.878 90.1361 94.1492 92.7152 86.896 92.7152C79.6429 92.7152 72.9141 90.0603 68.1078 85.5848H58.67C64.6997 94.2323 75.2735 99.3905 86.896 99.3905C98.5185 99.3905 109.092 94.2323 115.122 85.5848H105.684Z"
                fill="#0024A9"
              />
            </svg>
          </div>

          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 a 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Ao completar 20 anos, a ASN ganhou uma nova plataforma que, além de ser multimídia, traz uma interface mais
                acessível para o jornalista por meio do WordPress.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p className="mb-2">Alessandra Pires, Andréa Sekeff e Renata Mariz</p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Jornalismo de Dados</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jan/21-jun/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Com base nos dados gerados pela UGE e o conhecimento do jornalismo de dados da assessoria de imprensa, surgiu o
                projeto Jornalismo de Dados, que conta com a produção de infográficos exclusivos para o Poder360, um jornal
                digital de grande relevância no país.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Retomada e ampliação das pres strips</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019-jul/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                De 2019 até julho de 2022, cerca de 50 jornalistas participaram das press trips para cobrir projetos como Startup
                Summit, Indicação Geográfica, Prêmio de Inovação, Rio Innovation Week, Conexão, entre outros, com o objetivo de
                ampliar o acesso e conhecimento dos jornalistas dos principais veículos de comunicação nacional sobre os projetos
                do Sebrae.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Media training </>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 - 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Pela primeira vez, foi realizada uma capacitação dos principais porta-vozes do Sebrae nos estados para lidar com a
                imprensa. O treinamento ocorreu em formato online e presencial.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Assessoria digital e podcast</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 - 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Com os novos projetos, houve uma ampliação do alcance do Sebrae por meio do podcast e do conteúdo do Twitter.</p>
            </div>

            <div>
              <p className="leading-none text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Podcast:</strong>
              </p>
              <p>Mais de 1.200 visualizações no YouTube</p>
            </div>

            <div>
              <p className="leading-none text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Twitter:</strong>
              </p>
              <p>
                Interações: 2.050
                <br />
                Curtidas: 13.035
                <br />
                Compartilhamentos: 3.921
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block">
                <strong className="font-campuni-bold font-normal">Materiais Ilustrativos</strong>
              </p>

              {width < 1024 ? (
                <>
                  <img src={ImageAssessoria} alt=" " className="w-full block" />
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center pt-12">
                  <img src={ImageAssessoria} alt=" " />
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Parceria de mídia espontânea: Estadão, TV Justiça e TV Brasil</>}
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021-mai/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A parceria inédita com veículos nacionais para replicar e produzir conteúdos exclusivos do Sebrae gerou mídia sem
                custos para a instituição.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              <div className="w-box-full flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-6 pt-12">
                <img src={ImageNotebook1} alt=" " />
                <img src={ImageNotebook2} alt=" " />
              </div>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Fotojornalismo</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021-jul/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>A assessoria de imprensa produziu um banco de pautas de fotojornalismo para a Agência Sebrae de Notícias.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Alessandra Pires, Andréa Sekeff e Renata Mariz</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.flickr.com/photos/sebrae_nacional/'}>
                    Acesse as fotos
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.flickr.com/photos/sebrae_nacional/'}>
                    Acesse as fotos
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <TitleArea className="!pb-12 !pt-16">Comunicação Interna</TitleArea>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Modelo de Canais de Comunicação Interna de apoio ao Conexão Sebrae</>}
          position={'end'}
          className="!mt-0 lg:!mt-[4rem]"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021-2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Foram implementados os seguintes projetos: newsletter semanal, lives quinzenais e conteúdo diário nas TVs
                corporativas. Além disso, o novo sistema de canais de comunicação foi reorganizado.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Tatiana Mendonça e Carol Melles</p>
            </div>
          </div>
        </FlexibleBox>

        <TitleArea className="!pb-12 !pt-16">Canal Empreender</TitleArea>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Canal de TV fechada no ar 24h falando sobre empreendedorismo</>}
          position={'start'}
          className="!mt-0 lg:!mt-[4rem]"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Para ampliar a informação e orientar as pessoas que empreendem ou querem empreender, foi criado um canal de
                televisão específico para tratar sobre empreendedorismo, um meio de comunicação exclusivo, abrangente e inovador
                no Brasil.
              </p>
            </div>

            <p>
              É possível acompanhar a grade de programação, com mais de 40 programas, ao vivo no Youtube do Sebrae e em canais de
              TV fechada como Vivo (605), Sky (568), Net/Claro (562), além de plataformas de streaming como Vivo Play, Oi Play e
              Uol Play.
            </p>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Andrea Sekeff, Pedro Valadares e Raquel Chaves Bufon</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <Anchor href="https://sebrae.com.br/canalempreender">https://sebrae.com.br/canalempreender</Anchor>
            </div>

            <div className="w-box-full flex items-center justify-center pt-6">
              <img src={ImageSite} alt=" " />
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
