import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import { useBasic } from 'contexts';
import { ContentBox, Hero, LinkButton } from 'components';
import { icons } from 'components/NucleosDeAtuacao/icons';
import PATHS from 'routes/paths';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';
import ImageHero from 'assets/images/hero-home.jpg';
import ImageConquistas from 'assets/images/home/8-marca.jpg';
import ImageSaibaMais from 'assets/images/home/saiba-mais.jpg';
import ImageConheca from 'assets/images/home/conheca.png';

export default function HomePage() {
  const { width } = useBasic();

  const routes = PATHS.filter(route => route.id !== 'home');

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  const outrosDestaques: JSX.Element[] = [
    <>
      <span className="ico block mb-[20.59px]">
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.3267 21.9176C26.4733 20.7219 26.5833 19.5261 26.5833 18.2941C26.5833 17.0621 26.4733 15.8663 26.3267 14.6706H32.5233C32.8167 15.8301 33 17.044 33 18.2941C33 19.5442 32.8167 20.7581 32.5233 21.9176M23.0817 31.991C24.1817 29.98 25.025 27.8059 25.6117 25.5412H31.02C29.26 28.5306 26.455 30.8496 23.0817 31.991ZM22.6233 21.9176H14.0433C13.86 20.7219 13.75 19.5261 13.75 18.2941C13.75 17.0621 13.86 15.8482 14.0433 14.6706H22.6233C22.7883 15.8482 22.9167 17.0621 22.9167 18.2941C22.9167 19.5261 22.7883 20.7219 22.6233 21.9176ZM18.3333 32.7157C16.8117 30.5416 15.5833 28.132 14.8317 25.5412H21.835C21.0833 28.132 19.855 30.5416 18.3333 32.7157ZM11 11.047H5.64667C7.38833 8.03951 10.2117 5.72045 13.5667 4.59716C12.4667 6.60822 11.6417 8.78234 11 11.047ZM5.64667 25.5412H11C11.6417 27.8059 12.4667 29.98 13.5667 31.991C10.2117 30.8496 7.38833 28.5306 5.64667 25.5412ZM4.14333 21.9176C3.85 20.7581 3.66667 19.5442 3.66667 18.2941C3.66667 17.044 3.85 15.8301 4.14333 14.6706H10.34C10.1933 15.8663 10.0833 17.0621 10.0833 18.2941C10.0833 19.5261 10.1933 20.7219 10.34 21.9176M18.3333 3.85434C19.855 6.02845 21.0833 8.45622 21.835 11.047H14.8317C15.5833 8.45622 16.8117 6.02845 18.3333 3.85434ZM31.02 11.047H25.6117C25.025 8.78234 24.1817 6.60822 23.0817 4.59716C26.455 5.73857 29.26 8.03951 31.02 11.047ZM18.3333 0.176453C8.195 0.176453 0 8.32939 0 18.2941C0 23.0992 1.93154 27.7075 5.36971 31.1052C7.07212 32.7876 9.09317 34.1221 11.3175 35.0326C13.5418 35.9431 15.9258 36.4117 18.3333 36.4117C23.1956 36.4117 27.8588 34.5029 31.297 31.1052C34.7351 27.7075 36.6667 23.0992 36.6667 18.2941C36.6667 15.9149 36.1925 13.5589 35.2711 11.3608C34.3498 9.16264 32.9994 7.16537 31.297 5.48299C29.5946 3.80061 27.5735 2.46607 25.3492 1.55558C23.1249 0.64508 20.7409 0.176453 18.3333 0.176453V0.176453Z"
            fill="#2F80ED"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Mais de 5,3 milhões
      </p>
      <p className="desc font-alegreya font-medium text-dark-liver text-lg block">
        de novos usuários no portal Sebrae provenientes de campanhas
      </p>
    </>,
    <>
      <span className="ico block mb-[17.43px]">
        <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.2086 32.9915H5.22711L4.42024 37.3767H0L5.54284 9.69756H9.89292L15.4358 37.3767H10.9804L10.2086 32.9915ZM9.43686 28.6414L7.71788 18.7485L5.96382 28.6765H9.43686V28.6414Z"
            fill="#005EB8"
          />
          <path
            d="M23.4693 37.7275C21.5749 37.7275 20.0314 37.2013 18.8737 36.1138C17.5406 34.8509 16.9091 33.1319 16.9091 30.9218V27.3084H21.2592V30.9218C21.2592 31.8689 21.4697 32.5355 21.8556 32.8863C22.2064 33.202 22.7326 33.3774 23.4693 33.3774C24.2411 33.3774 24.7673 33.2371 25.0831 32.8863C25.469 32.5355 25.6794 31.8689 25.6794 30.9218V29.1677C25.6794 27.9398 25.1883 26.8874 24.206 26.0104C23.9955 25.835 23.4342 25.4842 22.5572 24.993C21.3294 24.3265 20.4172 23.7652 19.8209 23.239C18.8737 22.397 18.137 21.4498 17.6458 20.3623C17.1547 19.2748 16.9091 18.1171 16.9091 16.8892V16.2227C16.9091 14.0477 17.5757 12.2936 18.8737 11.0307C20.0314 9.94316 21.5749 9.41694 23.4693 9.41694C25.3637 9.41694 26.9073 9.94316 28.065 11.0307C29.3981 12.2936 30.0295 14.0126 30.0295 16.2227V18.6433H25.6794V16.2227C25.6794 15.2404 25.469 14.5739 25.0831 14.2582C24.7673 13.9424 24.2411 13.767 23.4693 13.767C22.7326 13.767 22.2064 13.9424 21.8556 14.2582C21.4697 14.644 21.2592 15.2755 21.2592 16.2227V16.8892C21.2592 18.1171 21.7503 19.1345 22.7326 20.0115C23.0133 20.222 23.5044 20.5377 24.2762 20.9587C25.6444 21.6954 26.5916 22.2918 27.1178 22.7829C29.0823 24.537 30.0295 26.6418 30.0295 29.2028V30.9568C30.0295 33.1319 29.363 34.8859 28.065 36.1489C26.9073 37.1662 25.3637 37.7275 23.4693 37.7275Z"
            fill="#005EB8"
          />
          <path
            d="M45.9215 37.3767H43.3956L36.7653 23.3442V37.3767H32.4152V9.73267H34.941L41.5714 23.7301V9.73267H45.9215V37.3767Z"
            fill="#005EB8"
          />
          <path
            d="M51.5687 14.5738H49.8497L49.4989 16.1876H49.1481L50.5163 10.0133H50.832L52.2002 16.1876H51.8494L51.5687 14.5738ZM51.4635 14.2581L50.6917 10.7149L49.9199 14.2581H51.4635Z"
            fill="#005EB8"
          />
          <path
            d="M53.8841 16.2928C53.4982 16.2928 53.2176 16.1876 53.0071 15.9771C52.7615 15.7315 52.6212 15.4158 52.6212 14.9597V11.2762C52.6212 10.8201 52.7615 10.5044 53.0071 10.2588C53.2176 10.0483 53.5333 9.94308 53.8841 9.94308C54.27 9.94308 54.5507 10.0483 54.7612 10.2588C55.0067 10.5044 55.147 10.8201 55.147 11.2762V11.8726H54.8313V11.2762C54.8313 10.9254 54.7261 10.6798 54.5507 10.5044C54.3753 10.3641 54.1648 10.2939 53.8841 10.2939C53.6035 10.2939 53.393 10.3641 53.2176 10.5044C53.0422 10.6798 52.9369 10.9254 52.9369 11.2762V14.9597C52.9369 15.3105 53.0422 15.5561 53.2176 15.7315C53.3579 15.8718 53.6035 15.942 53.8841 15.942C54.1648 15.942 54.4103 15.8718 54.5507 15.7315C54.7261 15.5561 54.8313 15.3105 54.8313 14.9597V13.2758H53.8841V12.9601H55.147V14.9597C55.147 15.4158 55.0067 15.7315 54.7612 15.9771C54.5857 16.1876 54.27 16.2928 53.8841 16.2928Z"
            fill="#005EB8"
          />
          <path
            d="M56.1294 12.8899H57.4625V13.2056H56.1294V15.8718H58.0238V16.1875H55.8137V10.0132H58.0238V10.329H56.1294V12.8899ZM57.1467 8.82048L57.7431 9.69751H57.4976L56.9363 9.06605L56.375 9.69751H56.1294L56.7258 8.82048H57.1467Z"
            fill="#005EB8"
          />
          <path
            d="M61.2865 16.1876H61.1111L59.0062 11.1709V16.1876H58.6905V10.0133H58.8659L60.9708 15.0299V10.0133H61.2865V16.1876Z"
            fill="#005EB8"
          />
          <path
            d="M63.2502 16.2928C62.8643 16.2928 62.5837 16.1876 62.3732 15.9771C62.1276 15.7315 61.9873 15.4158 61.9873 14.9597V11.2762C61.9873 10.8201 62.1276 10.5044 62.3732 10.2588C62.5837 10.0483 62.8994 9.94308 63.2502 9.94308C63.6361 9.94308 63.9167 10.0483 64.1272 10.2588C64.3728 10.5044 64.5131 10.8201 64.5131 11.2762V11.8726H64.1974V11.2762C64.1974 10.9254 64.0921 10.6798 63.9167 10.5044C63.7764 10.3641 63.5308 10.2939 63.2502 10.2939C62.9696 10.2939 62.724 10.3641 62.5837 10.5044C62.4082 10.6798 62.303 10.9254 62.303 11.2762V14.9597C62.303 15.3105 62.4082 15.5561 62.5837 15.7315C62.724 15.8718 62.9696 15.942 63.2502 15.942C63.5308 15.942 63.7764 15.8718 63.9167 15.7315C64.0921 15.5561 64.1974 15.3105 64.1974 14.9597V14.0827H64.5131V14.9597C64.5131 15.4158 64.3728 15.7315 64.1272 15.9771C63.9167 16.1876 63.6361 16.2928 63.2502 16.2928Z"
            fill="#005EB8"
          />
          <path d="M65.5315 16.1876H65.2158V10.0133H65.5315V16.1876Z" fill="#005EB8" />
          <path
            d="M68.4778 14.5738H66.7589L66.408 16.1876H66.0572L67.4254 10.0133H67.7411L69.1093 16.1876H68.7585L68.4778 14.5738ZM68.3726 14.2581L67.6008 10.7149L66.829 14.2581H68.3726Z"
            fill="#005EB8"
          />
          <path
            d="M50.6918 26.8523C50.2708 26.8523 49.92 26.747 49.6744 26.5015C49.3938 26.2208 49.2184 25.8349 49.2184 25.3438V24.5369H50.2007V25.3438C50.2007 25.5543 50.2357 25.6946 50.341 25.7998C50.4111 25.87 50.5515 25.9051 50.6918 25.9051C50.8672 25.9051 50.9724 25.87 51.0426 25.7998C51.1479 25.7297 51.1829 25.5894 51.1829 25.3438V24.9579C51.1829 24.6772 51.0777 24.4667 50.8672 24.2563C50.8321 24.2212 50.6918 24.151 50.4813 24.0107C50.2007 23.8704 49.9902 23.73 49.8849 23.6248C49.6744 23.4494 49.499 23.2389 49.3938 22.9933C49.2885 22.7478 49.2184 22.5022 49.2184 22.2215V22.0812C49.2184 21.5901 49.3587 21.2042 49.6744 20.9235C49.92 20.678 50.2708 20.5727 50.6918 20.5727C51.1128 20.5727 51.4636 20.678 51.7091 20.9235C51.9898 21.2042 52.1652 21.5901 52.1652 22.0812V22.6074H51.1829V22.0812C51.1829 21.8707 51.1479 21.7304 51.0426 21.6252C50.9724 21.555 50.8672 21.5199 50.6918 21.5199C50.5164 21.5199 50.4111 21.555 50.341 21.6252C50.2357 21.6953 50.2007 21.8707 50.2007 22.0812V22.2215C50.2007 22.5022 50.3059 22.7127 50.5164 22.9232C50.5865 22.9583 50.6918 23.0284 50.8672 23.1337C51.1829 23.3091 51.3934 23.4494 51.4987 23.5546C51.9196 23.9405 52.1652 24.4317 52.1652 24.993V25.3789C52.1652 25.87 52.0249 26.2559 51.7091 26.5365C51.4636 26.747 51.1128 26.8523 50.6918 26.8523Z"
            fill="#005EB8"
          />
          <path
            d="M53.7086 21.5901V23.1336H54.7961V24.1159H53.7086V25.8349H55.2872V26.8172H52.7263V20.6078H55.2872V21.5901H53.7086Z"
            fill="#005EB8"
          />
          <path
            d="M57.3572 26.7821H55.8837V20.6078H57.2168C57.6378 20.6078 57.9886 20.713 58.2342 20.9586C58.3745 21.0989 58.5148 21.2743 58.585 21.4497C58.6552 21.6252 58.6902 21.8707 58.6902 22.1163V22.6776C58.6902 23.0986 58.585 23.4143 58.3394 23.6248C58.6552 23.8704 58.8306 24.2212 58.8306 24.7474V25.3087C58.8306 25.5543 58.7955 25.7647 58.7253 25.9401C58.6552 26.1506 58.5499 26.291 58.3745 26.4313C58.0939 26.6769 57.7781 26.7821 57.3572 26.7821ZM57.2168 23.2038C57.3922 23.2038 57.4975 23.1687 57.5676 23.0986C57.6729 22.9933 57.708 22.853 57.708 22.6425V22.0812C57.708 21.8707 57.6729 21.7304 57.5676 21.6602C57.4975 21.5901 57.3572 21.555 57.1817 21.555H56.8309V23.2038H57.2168ZM56.866 25.7998H57.3572C57.5326 25.7998 57.6729 25.7647 57.743 25.6946C57.8132 25.6244 57.8834 25.4841 57.8834 25.2736V24.7123C57.8834 24.5018 57.8483 24.3615 57.743 24.2562C57.6729 24.1861 57.5326 24.151 57.3922 24.151H56.9011V25.7998H56.866Z"
            fill="#005EB8"
          />
          <path
            d="M62.3034 26.7821H61.3211L60.83 24.2913H60.3739V26.7821H59.3917V20.6078H60.8651C61.2861 20.6078 61.6369 20.7481 61.8824 20.9937C62.1631 21.2743 62.3385 21.6602 62.3385 22.1514V22.8179C62.3385 23.3091 62.1982 23.6949 61.8824 23.9756C61.8474 24.0107 61.7772 24.0458 61.7421 24.0808L62.3034 26.7821ZM61.3562 22.1163C61.3562 21.9058 61.3211 21.7655 61.2159 21.6602C61.1457 21.5901 61.0054 21.555 60.8651 21.555H60.3739V23.3091H60.8651C61.0405 23.3091 61.1457 23.274 61.2159 23.2038C61.3211 23.1336 61.3562 22.9582 61.3562 22.7478V22.1163Z"
            fill="#005EB8"
          />
          <path
            d="M64.9344 25.7998H63.8468L63.6714 26.7821H62.6892L63.917 20.6078H64.8993L66.1271 26.7821H65.1449L64.9344 25.7998ZM64.794 24.8175L64.4081 22.6074L64.0222 24.8175H64.794Z"
            fill="#005EB8"
          />
          <path
            d="M67.5662 21.5901V23.1336H68.6537V24.1159H67.5662V25.8349H69.1449V26.8172H66.5839V20.6078H69.1449V21.5901H67.5662Z"
            fill="#005EB8"
          />
          <path
            d="M50.621 37.3767H49.358V31.2023H50.621C51.0069 31.2023 51.2875 31.3076 51.498 31.5181C51.7436 31.7637 51.8839 32.0794 51.8839 32.5354V36.1137C51.8839 36.5698 51.7436 36.8855 51.498 37.1311C51.2875 37.2714 51.0069 37.3767 50.621 37.3767ZM49.7089 37.0258H50.656C51.2875 37.0258 51.6032 36.7101 51.6032 36.0786V32.5004C51.6032 32.1495 51.498 31.904 51.3226 31.7286C51.1472 31.5882 50.9367 31.5181 50.656 31.5181H49.7089V37.0258Z"
            fill="#005EB8"
          />
          <path
            d="M52.8314 31.5181V34.0439H54.1645V34.3597H52.8314V37.0258H54.7258V37.3416H52.5157V31.2023H54.7258V31.5181H52.8314Z"
            fill="#005EB8"
          />
          <path
            d="M59.3214 37.3767H59.146L57.0411 32.36V37.3767H56.7254V31.2023H56.9008L59.0057 36.219V31.2023H59.3214V37.3767Z"
            fill="#005EB8"
          />
          <path
            d="M61.2856 37.4468C60.8997 37.4468 60.6191 37.3416 60.4086 37.1311C60.163 36.8855 60.0227 36.5698 60.0227 36.1137V32.4302C60.0227 31.9741 60.163 31.6584 60.4086 31.4128C60.6191 31.2024 60.9348 31.0971 61.2856 31.0971C61.6715 31.0971 61.9521 31.2024 62.1626 31.4128C62.4082 31.6584 62.5485 31.9741 62.5485 32.4302V36.1137C62.5485 36.5698 62.4082 36.8855 62.1626 37.1311C61.9521 37.3416 61.6715 37.4468 61.2856 37.4468ZM61.2856 31.4479C61.0049 31.4479 60.7945 31.5181 60.6191 31.6584C60.4436 31.8338 60.3384 32.0794 60.3384 32.4302V36.1137C60.3384 36.4645 60.4436 36.7101 60.6191 36.8855C60.7594 37.0258 61.0049 37.096 61.2856 37.096C61.5662 37.096 61.8118 37.0258 61.9521 36.8855C62.1276 36.7101 62.2328 36.4645 62.2328 36.1137V32.4302C62.2328 32.0794 62.1276 31.8338 61.9521 31.6584C61.8118 31.5181 61.5662 31.4479 61.2856 31.4479Z"
            fill="#005EB8"
          />
          <path d="M64.4086 31.5181V37.3767H64.0928V31.5181H62.9351V31.2023H65.6013V31.5181H64.4086Z" fill="#005EB8" />
          <path
            d="M66.373 37.3767H66.0572V31.2024H66.373V37.3767ZM66.6887 30.0096L66.0923 30.8867H66.3379L67.1097 30.0096H66.6887Z"
            fill="#005EB8"
          />
          <path
            d="M68.3376 37.4468C67.9517 37.4468 67.6711 37.3416 67.4606 37.1311C67.215 36.8855 67.0747 36.5698 67.0747 36.1137V32.4302C67.0747 31.9741 67.215 31.6584 67.4606 31.4128C67.6711 31.2024 67.9868 31.0971 68.3376 31.0971C68.7235 31.0971 69.0041 31.2024 69.2146 31.4128C69.4602 31.6584 69.6005 31.9741 69.6005 32.4302V33.0266H69.2848V32.4302C69.2848 32.0794 69.1795 31.8338 69.0041 31.6584C68.8638 31.5181 68.6182 31.4479 68.3376 31.4479C68.0569 31.4479 67.8114 31.5181 67.6711 31.6584C67.4956 31.8338 67.3904 32.0794 67.3904 32.4302V36.1137C67.3904 36.4645 67.4956 36.7101 67.6711 36.8855C67.8114 37.0258 68.0569 37.096 68.3376 37.096C68.6182 37.096 68.8638 37.0258 69.0041 36.8855C69.1795 36.7101 69.2848 36.4645 69.2848 36.1137V35.2367H69.6005V36.1137C69.6005 36.5698 69.4602 36.8855 69.2146 37.1311C69.0041 37.3416 68.7235 37.4468 68.3376 37.4468Z"
            fill="#005EB8"
          />
          <path d="M70.618 37.3767H70.3023V31.2023H70.618V37.3767Z" fill="#005EB8" />
          <path
            d="M73.5645 35.7279H71.8455L71.4947 37.3416H71.1439L72.5121 31.1673H72.8278L74.196 37.3416H73.8452L73.5645 35.7279ZM73.4593 35.4121L72.6875 31.8689L71.9157 35.4121H73.4593Z"
            fill="#005EB8"
          />
          <path
            d="M75.915 37.4468C75.5291 37.4468 75.2485 37.3416 75.038 37.1311C74.7924 36.8855 74.6521 36.5698 74.6521 36.1137V35.2367H74.9678V36.1488C74.9678 36.4996 75.0731 36.7452 75.2485 36.9206C75.3888 37.0609 75.6344 37.1311 75.915 37.1311C76.1957 37.1311 76.4062 37.0609 76.5816 36.9206C76.757 36.7452 76.8622 36.4996 76.8622 36.1488V35.7278C76.8622 35.3069 76.757 34.9911 76.5114 34.7806C76.3009 34.5702 76.0553 34.4298 75.8448 34.3246C75.5642 34.2193 75.3186 34.0439 75.038 33.7984C74.7573 33.5528 74.617 33.1669 74.617 32.6407V32.4302C74.617 31.9741 74.7573 31.6584 75.0029 31.4128C75.2134 31.2024 75.5291 31.0971 75.8799 31.0971C76.2658 31.0971 76.5465 31.2024 76.757 31.4128C77.0025 31.6584 77.1429 31.9741 77.1429 32.4302V33.0266H76.8271V32.4302C76.8271 32.0794 76.7219 31.8338 76.5465 31.6584C76.4062 31.5181 76.1957 31.4479 75.8799 31.4479C75.5642 31.4479 75.3537 31.5181 75.2134 31.6584C75.038 31.8338 74.9327 32.0794 74.9327 32.4302V32.6056C74.9327 32.9213 75.0029 33.1669 75.1432 33.3423C75.2836 33.5528 75.4239 33.658 75.5291 33.7633C75.6694 33.8335 75.8098 33.9036 75.9501 33.9738C76.757 34.3246 77.1429 34.8859 77.1429 35.6928V36.1137C77.1429 36.5698 77.0025 36.8855 76.757 37.1311C76.5816 37.3416 76.3009 37.4468 75.915 37.4468Z"
            fill="#005EB8"
          />
          <path
            d="M9.36672 7.24191C11.2962 5.13703 13.9974 3.94427 16.9092 3.94427C19.8209 3.94427 22.5222 5.17211 24.4517 7.24191H28.2404C25.8198 3.24264 21.575 0.857117 16.9092 0.857117C12.2434 0.857117 7.99855 3.24264 5.57794 7.24191H9.36672Z"
            fill="#005EB8"
          />
          <path
            d="M42.1321 40.1832C40.2027 42.2881 37.5014 43.4809 34.5897 43.4809C31.6779 43.4809 28.9767 42.253 27.0472 40.1832H23.2584C25.679 44.1825 29.9239 46.568 34.5897 46.568C39.2555 46.568 43.5003 44.1825 45.9209 40.1832H42.1321Z"
            fill="#005EB8"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Criação da nova Agência Sebrae de Notícias
      </p>
    </>,
    <>
      <span className="ico block mb-[22px]">
        <svg width="45" height="36" viewBox="0 0 45 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 25.7143L29.6775 18L18 10.2857V25.7143ZM44.01 5.58C44.3025 6.78857 44.505 8.40857 44.64 10.4657C44.7975 12.5229 44.865 14.2971 44.865 15.84L45 18C45 23.6314 44.64 27.7714 44.01 30.42C43.4475 32.7343 42.1425 34.2257 40.1175 34.8686C39.06 35.2029 37.125 35.4343 34.155 35.5886C31.23 35.7686 28.5525 35.8457 26.0775 35.8457L22.5 36C13.0725 36 7.2 35.5886 4.8825 34.8686C2.8575 34.2257 1.5525 32.7343 0.99 30.42C0.6975 29.2114 0.495 27.5914 0.36 25.5343C0.2025 23.4771 0.135 21.7029 0.135 20.16L0 18C0 12.3686 0.36 8.22857 0.99 5.58C1.5525 3.26571 2.8575 1.77429 4.8825 1.13143C5.94 0.797143 7.875 0.565714 10.845 0.411428C13.77 0.231428 16.4475 0.154286 18.9225 0.154286L22.5 0C31.9275 0 37.8 0.411429 40.1175 1.13143C42.1425 1.77429 43.4475 3.26571 44.01 5.58Z"
            fill="#2F80ED"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Mais de 725 mil inscritos no canal do Youtube
      </p>
      <p className="desc font-alegreya font-medium text-dark-liver text-lg block">
        Mais de uma produção audiovisual por dia no ano de 2022
      </p>
    </>,
    <>
      <span className="ico block mb-[24px]">
        <svg width="45" height="29" viewBox="0 0 45 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.5 0C24.2405 0 25.9097 0.737498 27.1404 2.05025C28.3711 3.36301 29.0625 5.14348 29.0625 7C29.0625 8.85652 28.3711 10.637 27.1404 11.9497C25.9097 13.2625 24.2405 14 22.5 14C20.7595 14 19.0903 13.2625 17.8596 11.9497C16.6289 10.637 15.9375 8.85652 15.9375 7C15.9375 5.14348 16.6289 3.36301 17.8596 2.05025C19.0903 0.737498 20.7595 0 22.5 0ZM9.375 5C10.425 5 11.4 5.3 12.2438 5.84C11.9625 8.7 12.75 11.54 14.3625 13.76C13.425 15.68 11.55 17 9.375 17C7.88316 17 6.45242 16.3679 5.39752 15.2426C4.34263 14.1174 3.75 12.5913 3.75 11C3.75 9.4087 4.34263 7.88258 5.39752 6.75736C6.45242 5.63214 7.88316 5 9.375 5ZM35.625 5C37.1168 5 38.5476 5.63214 39.6025 6.75736C40.6574 7.88258 41.25 9.4087 41.25 11C41.25 12.5913 40.6574 14.1174 39.6025 15.2426C38.5476 16.3679 37.1168 17 35.625 17C33.45 17 31.575 15.68 30.6375 13.76C32.25 11.54 33.0375 8.7 32.7562 5.84C33.6 5.3 34.575 5 35.625 5ZM10.3125 25.5C10.3125 21.36 15.7688 18 22.5 18C29.2313 18 34.6875 21.36 34.6875 25.5V29H10.3125V25.5ZM0 29V26C0 23.22 3.54375 20.88 8.34375 20.2C7.2375 21.56 6.5625 23.44 6.5625 25.5V29H0ZM45 29H38.4375V25.5C38.4375 23.44 37.7625 21.56 36.6562 20.2C41.4562 20.88 45 23.22 45 26V29Z"
            fill="#2F80ED"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Mais de 1,4 bilhões
      </p>
      <p className="desc font-alegreya font-medium text-dark-liver text-lg block">
        de espectadores em mais de 140 ações publicitárias
      </p>
    </>,
    <>
      <span className="ico block mb-[17px]">
        <svg width="36" height="45" viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M30.3896 0C31.7329 0 33.0212 0.526784 33.9711 1.46447C34.9209 2.40215 35.4545 3.67392 35.4545 5V45L17.7273 37.5L0 45V5C0 2.225 2.27922 0 5.06494 0H30.3896ZM15.1948 27.5L31.0227 11.9L27.452 8.35L15.1948 20.45L8.63571 13.975L5.06494 17.5L15.1948 27.5Z"
            fill="#2F80ED"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Novo Brandbook da Marca Sebrae
      </p>
    </>,
    <>
      <span className="ico block mb-[20px]">
        <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M46 18H36C35.4696 18 34.9609 18.2107 34.5858 18.5858C34.2107 18.9609 34 19.4696 34 20V38C34 38.5304 34.2107 39.0391 34.5858 39.4142C34.9609 39.7893 35.4696 40 36 40H46C46.5304 40 47.0391 39.7893 47.4142 39.4142C47.7893 39.0391 48 38.5304 48 38V20C48 19.4696 47.7893 18.9609 47.4142 18.5858C47.0391 18.2107 46.5304 18 46 18ZM46 36H36V22H46V36ZM40 0H4C1.78 0 0 1.78 0 4V28C0 29.0609 0.421427 30.0783 1.17157 30.8284C1.92172 31.5786 2.93913 32 4 32H18V36H14V40H30V36H26V32H30V28H4V4H40V14H44V4C44 1.78 42.2 0 40 0ZM23.94 14L22 8L20.06 14H14L18.94 17.52L17.06 23.34L22 19.74L26.94 23.34L25.06 17.52L30 14H23.94Z"
            fill="#2F80ED"
          />
        </svg>
      </span>
      <p className="title mb-1 font-campuni-bold font-normal block text-[22px] leading-none text-dark-charcoal">
        Mais de 8,2 milhões
      </p>
      <p className="desc font-alegreya font-medium text-dark-liver text-lg block">de seguidores nas redes sociais</p>
    </>,
  ];

  return (
    <>
      <Hero
        colorTheme="#40BBFF"
        title="UCOM"
        description="Um quadriênio de trabalho, planejamento, reestruturação, mudanças e resultados positivos para o Sebrae."
        image={
          <img
            src={ImageHero}
            alt="Equipe de trabalho da Unidade de Comunicação em pose para foto, perfilados em 4 linhas somando de 33 pessoas entre homens e mulheres"
          />
        }
        isHome
      />

      <ContentBox className="!pb-0 !pl-0 !pr-0 !max-w-none">
        <div className="w-full max-w-[1050px] mx-auto px-5 lg:px-0 pb-12 lg:pb-10 pt-12">
          <h2 className="text-dark-liver font-campuni-bold font-normal leading-none text-[32px] lg:text-[40px] text-center block mb-3 lg:mb-4 uppercase lg:normal-case">
            Missão da UCOM:
          </h2>
          <p className="block text-center font-alegreya font-normal text-[20px] lg:text-[22px] leading-snug">
            Desenvolver e proteger a marca, o posicionamento e os atributos desejados para a marca Sebrae e promover a imagem
            institucional e de seus produtos e serviços para os públicos de interesse da instituição.
          </p>
        </div>

        <div className="bg-palatinate-blue relative w-full pb-12 lg:pb-0 pt-12 px-5 lg:px-0 flex justify-center overflow-hidden">
          <div className="w-full lg:max-w-[1280px] mx-auto">
            <p className="font-alegreya text-xl lg:text-[32px] lg:leading-tight text-cultured text-center w-full max-w-[1050px] mx-auto lg:mb-8 uppercase lg:normal-case">
              <strong>
                Nesses quatro anos, por meio de uma comunicação integrada e multidisciplinar, nos dedicamos a desenvolver
                estratégias e soluções criativas para fortalecer a marca Sebrae e apoiar o crescimento dos pequenos negócios.
              </strong>
            </p>
            <p className="font-alegreya text-xl lg:text-[32px] lg:leading-tight text-cultured text-center w-full max-w-[1050px] mx-auto mb-11 uppercase lg:normal-case font-bold lg:font-normal">
              Nesse tempo conquistamos:
            </p>

            <div className="home-conquistas flex flex-col-reverse lg:flex-row items-start justify-between relative lg:pb-12">
              <img
                src={ImageConquistas}
                alt="Banner com o conteúdo da 8ª marca mais forte do Brasil"
                className="w-full lg:w-[688px] relative z-10 mt-8 lg:mt-0"
              />

              <ul className="text-anti-flash-white-2 space-y-6 lg:space-y-8 w-full lg:w-[535px] lg:pr-[80px] relative z-10 text-center lg:text-left">
                <li>
                  <p className="text-base lg:text-2xl">
                    <strong className="font-campuni-medium">8ª marca mais forte do país</strong>
                  </p>
                  <p className="font-campuni-light text-base lg:text-xl">
                    Estamos entre as marcas mais fortes do Brasil, ao lado de players como Nubank, iFood, Tramontina, O Boticário,
                    Americanas e Magazine Luiza, de acordo com estudo da IstoÉ Dinheiro.
                  </p>
                </li>
                <li>
                  <p className="text-base lg:text-2xl">
                    <strong className="font-campuni-medium">Valor da marca</strong>
                  </p>
                  <p className="font-campuni-light text-base lg:text-xl">
                    A marca Sebrae foi avaliada em mais de 7,6 bilhões em 2021, representando um aumento de 138% em sua valoração
                    em comparação ao ano anterior, de acordo com a metodologia Kantar.
                  </p>
                </li>
                <li>
                  <p className="text-base lg:text-2xl">
                    <strong className="font-campuni-medium">Imagem positiva</strong>
                  </p>
                  <p className="font-campuni-light text-base lg:text-xl">
                    A imagem do Sebrae, em 2022, chegou ao patamar de 8,7 para os pequenos negócios e 8,4 para a sociedade.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[1120px] mx-auto pb-20 pt-24 lg:pt-12">
          <h2 className="text-bleu-de-france block text-center font-campuni-bold font-normal text-3xl lg:text-5xl mb-4 px-5 lg:px-0">
            Outros destaques da UCOM
          </h2>
          <p className="font-alegreya block text-center mb-12 lg:mb-10 text-dark-liver text-base lg:text-[22px] lg:leading-snug px-5 lg:px-0">
            <strong>Alguns dos números que refletem o impacto da UCOM na vida de milhões de pessoas e pequenos negócios</strong>
          </p>

          {width >= 1024 ? (
            <>
              <ul className="flex w-full items-stretch justify-between flex-wrap">
                {outrosDestaques.map((item, index) => (
                  <li key={index + 2 * 4} className="bg-white-1 border border-[#E0E0E0] rounded mb-4 p-6 w-[548px]">
                    {item}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={pagination}
                modules={[Pagination, FreeMode]}
                className="carousel-outros-destaques w-full max-w-[320px]"
              >
                {outrosDestaques.map((item, index) => (
                  <SwiperSlide
                    className="bg-white-1 border border-[#E0E0E0] rounded p-6 w-full max-w-[320px] min-h-[212px] flex flex-col items-center text-center"
                    key={index + 8 * 2}
                  >
                    {item}
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </div>

        <div className="home-see-more">
          <div className="head bg-honolulu-blue lg:h-[205px] w-full relative lg:flex items-center justify-center">
            <div className="w-full max-w-[1120px] mx-auto px-5 lg:px-0 py-8 lg:py-0">
              <h2 className="uppercase text-xl lg:text-[22px] lg:leading-relaxed text-anti-flash-white font-campuni-regular font-normal">
                Saiba mais sobre os <br className="block lg:hidden" />
                projetos da Unidade <br />
                de Comunicação por Núcleo:
              </h2>
            </div>
          </div>
          <div className="menu w-full max-w-[1120px] mx-auto">
            <div className="img">
              <img
                src={ImageSaibaMais}
                alt="Mulher jovem mexendo em seu celular, sentada em frente a um computador em seu escritório de trabalho"
              />
            </div>
            <div className="nav">
              <nav>
                <ul className="space-y-6">
                  {routes.map((route, index) => (
                    <li key={route.id}>
                      <Link to={route.pathname} className={`link-item font-alegreya`}>
                        <span className="ico">{icons[route.id]}</span>
                        <span className="title font-alegreya">{route.id === 'bi' ? 'BI / Digital' : route.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          <div className="conheca w-full max-w-[1280px] mx-auto">
            <div className="text">
              <h2>Conheça a UCOM</h2>
              <p>
                Conheça as equipes formadas por pessoas responsáveis por movimentar os diversos núcleos da Unidade de Comunicação
                do Sebrae.
              </p>

              <Link
                to={'/quem-faz-a-ucom'}
                className={`link-button rounded-md w-full lg:w-auto max-w-[320px] max-w-full py-4 px-[40px] leading-none inline-flex items-center font-campuni-bold font-normal text-lg justify-center transition-all duration-200 hover:opacity-80`}
                style={{ '--color-theme': '#FEFEFE' || '', '--color-text': '#0024A9' } as React.CSSProperties}
              >
                Clique aqui e conheça
              </Link>
            </div>

            <div className="img">
              <img
                src={ImageConheca}
                alt="Homem em pé fazendo uma apresentação em uma sala de reunião com pessoas sentadas em frente a seus notebooks"
              />
            </div>
          </div>
        </div>
      </ContentBox>
    </>
  );
}
