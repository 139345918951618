import './style.scss';

export interface LinkButtonInterface {
  className?: string;
  colorTheme: string;
  colorText?: string;
  url: string;
  children: string | React.ReactNode;
  [key: string]: any;
}

export function LinkButton({ className, colorTheme, colorText, url, children, ...rest }: LinkButtonInterface) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`link-button rounded-md p-4 leading-none inline-flex items-center font-campuni-bold font-normal text-lg transition-all duration-200 hover:opacity-80 ${
        className || ''
      }`}
      style={{ '--color-theme': colorTheme || '', '--color-text': colorText } as React.CSSProperties}
      {...rest}
    >
      {children}
    </a>
  );
}
