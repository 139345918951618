import React from 'react';

import HomePage from '../pages/Home';
import ConteudoPage from 'pages/Conteudo';
import PromocaoPage from 'pages/Promocao';
import CentralDeProducaoPage from 'pages/CentralDeProducao';
import BrandingPage from 'pages/Branding';
import RedesSociaisPage from 'pages/RedesSociais';
import AtendimentoPage from 'pages/Atendimento';
import ProjetoEspecialPage from 'pages/ProjetoEspecial';
import PublicacoesPage from 'pages/Publicacoes';
import AdministrativoPage from 'pages/Administrativo';
import BIPage from 'pages/BI';
import QuemFazAUcomPage from 'pages/QuemFazAUcom';

export interface pathsInterface {
  id: string;
  title: React.ReactNode;
  pathname: string;
  colorHover: string;
  component: React.ReactNode;
  children?: this[];
}

const PATHS: pathsInterface[] = [
  {
    id: 'home',
    title: 'Início',
    pathname: '/',
    colorHover: '',
    component: <HomePage />,
  },
  {
    id: 'conteudo',
    title: <>Conteúdo</>,
    pathname: '/conteudo',
    colorHover: '#9285F9',
    component: <ConteudoPage />,
  },
  {
    id: 'promocao',
    title: <>Promoção</>,
    pathname: '/promocao',
    colorHover: '#84F4BC',
    component: <PromocaoPage />,
  },
  {
    id: 'central-de-producao',
    title: (
      <>
        Central de <br />
        Produção
      </>
    ),
    pathname: '/central-de-producao',
    colorHover: '#FFABAB',
    component: <CentralDeProducaoPage />,
  },
  {
    id: 'branding',
    title: <>Branding</>,
    pathname: '/branding',
    colorHover: '#6FBFBE',
    component: <BrandingPage />,
  },
  {
    id: 'redes-sociais',
    title: (
      <>
        Redes <br />
        Sociais
      </>
    ),
    pathname: '/redes-sociais',
    colorHover: '#CD5BE8',
    component: <RedesSociaisPage />,
  },
  {
    id: 'atendimento',
    title: <>Atendimento</>,
    pathname: '/atendimento',
    colorHover: '#40BBFF',
    component: <AtendimentoPage />,
  },
  {
    id: 'projeto-especial',
    title: (
      <>
        Projeto <br />
        Especial
      </>
    ),
    pathname: '/projeto-especial',
    colorHover: '#FFB380',
    component: <ProjetoEspecialPage />,
  },
  {
    id: 'publicacoes',
    title: <>Publicações</>,
    pathname: '/publicacoes',
    colorHover: '#FFED69',
    component: <PublicacoesPage />,
  },
  {
    id: 'administrativo',
    title: <>Administrativo</>,
    pathname: '/administrativo',
    colorHover: '#006EC7',
    component: <AdministrativoPage />,
  },
  {
    id: 'bi',
    title: <>BI</>,
    pathname: '/bi',
    colorHover: '#F4455A',
    component: <BIPage />,
  },
  {
    id: 'quem-faz-a-ucom',
    title: (
      <>
        Quem faz <br />a UCOM
      </>
    ),
    pathname: '/quem-faz-a-ucom',
    colorHover: '#E4F998',
    component: <QuemFazAUcomPage />,
  },
];

export default PATHS;
