import { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import { useBasic } from 'contexts';
import { ContentBox, Hero, NucleosDeAtuacao } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';
import ImageHero from 'assets/images/hero-atendimento.jpg';
import aguardando from 'assets/images/atendimento/aguardando.png';
import ativos from 'assets/images/atendimento/ativos.png';
import cancelados from 'assets/images/atendimento/cancelados.png';
import concluidos from 'assets/images/atendimento/concluidos.png';
import qtd_atendimentos from 'assets/images/atendimento/qtd_atendimentos.png';
import valor_total_por_ano from 'assets/images/atendimento/valor_total_por_ano.png';
import visao_geral_2019 from 'assets/images/atendimento/visao_geral_2019.png';
import visao_geral_2020 from 'assets/images/atendimento/visao_geral_2020.png';
import visao_geral_2021 from 'assets/images/atendimento/visao_geral_2021.png';
import visao_geral_2022 from 'assets/images/atendimento/visao_geral_2022.png';
import daf_atendimentos from 'assets/images/atendimento/daf_atendimentos.png';
import daf_graph from 'assets/images/atendimento/daf_graph.png';
import daf_por_unidades_2019 from 'assets/images/atendimento/daf_por_unidades_2019.png';
import daf_por_unidades_2020 from 'assets/images/atendimento/daf_por_unidades_2020.png';
import daf_por_unidades_2021 from 'assets/images/atendimento/daf_por_unidades_2021.png';
import daf_por_unidades_2022 from 'assets/images/atendimento/daf_por_unidades_2022.png';
import ditec_atendimentos from 'assets/images/atendimento/ditec_atendimentos.png';
import ditec_graph from 'assets/images/atendimento/ditec_graph.png';
import ditec_por_unidades_2019 from 'assets/images/atendimento/ditec_por_unidades_2019.png';
import ditec_por_unidades_2020 from 'assets/images/atendimento/ditec_por_unidades_2020.png';
import ditec_por_unidades_2021 from 'assets/images/atendimento/ditec_por_unidades_2021.png';
import ditec_por_unidades_2022 from 'assets/images/atendimento/ditec_por_unidades_2022.png';
import presi_atendimentos from 'assets/images/atendimento/presi_atendimentos.png';
import presi_graph from 'assets/images/atendimento/presi_graph.png';
import presi_por_unidades_2019 from 'assets/images/atendimento/presi_por_unidades_2019.png';
import presi_por_unidades_2020 from 'assets/images/atendimento/presi_por_unidades_2020.png';
import presi_por_unidades_2021 from 'assets/images/atendimento/presi_por_unidades_2021.png';
import presi_por_unidades_2022 from 'assets/images/atendimento/presi_por_unidades_2022.png';

export default function AtendimentoPage() {
  const { width } = useBasic();
  const colorTheme = '#40BBFF';

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Atendimento"
        description="Coleta de forma recorrente e orgânica as demandas, as necessidades e os interesses de comunicação, elaborando o briefing, planejando, coordenando os esforços de atendimento do job e avaliando seu desempenho."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-8 lg:pt-[38px] !pb-[55px] !px-0 lg:!px-[58px] page-atendimento">
        <h2 className="title-1">
          Estatísticas de atendimento <br />
          2019 a 2022
        </h2>

        <div className="table-data visao-geral-ano">
          <div className="head">
            <h3 className="title">Visão geral por ano</h3>
            <div className="desc">
              <p>
                Quantidade total de atendimento de todos os anos por status <br />
                (concluídos, ativos, aguardando e cancelados)
              </p>
            </div>
          </div>

          <div className="body">
            <div className="first flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
              <img src={valor_total_por_ano} alt=" " />
              <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between lg:flex-wrap w-full lg:max-w-[603px]">
                <img src={qtd_atendimentos} alt=" " />
                <img src={ativos} alt=" " />
                <img src={concluidos} alt=" " />
                <img src={aguardando} alt=" " />
                <img src={cancelados} alt=" " />
              </div>
            </div>
          </div>
        </div>

        <div className="table-data visao-geral-ano-diretoria">
          <div className="head">
            <h3 className="title">Visão geral por ano e diretoria</h3>
            <div className="desc">
              <p>
                Confira todos os atendimentos feitos pela UCOM (inclusive os cancelados) agrupados por ano e diretoria das
                unidades que solicitaram o atendimento.
              </p>
            </div>
          </div>

          <div className="body">
            <div className="first lg:flex items-center justify-between">
              {width >= 1024 ? (
                <>
                  <img src={visao_geral_2019} alt=" " />
                  <img src={visao_geral_2020} alt=" " />
                  <img src={visao_geral_2021} alt=" " />
                  <img src={visao_geral_2022} alt=" " />
                </>
              ) : (
                <>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={pagination}
                    modules={[Pagination, FreeMode]}
                    className="carousel-atendimento-mobo"
                  >
                    <SwiperSlide>
                      <img src={visao_geral_2019} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={visao_geral_2020} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={visao_geral_2021} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={visao_geral_2022} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>

        <h2 className="title-2">Por ano e unidades de diretoria</h2>

        <p>Confira a quantidade de atendimentos realizados por ano e unidades das diretorias DITEC, PRESI e DAF:</p>

        <div className="table-data diretorias">
          <div className="head">
            <h3 className="title">DITEC</h3>
            <div className="desc">
              <p>Quantidade total de atendimento para a diretoria em todos os anos:</p>
            </div>
          </div>

          <div className="body">
            <div className="first flex flex-col-reverse lg:flex-row items-center lg:items-start justify-start lg:space-x-6">
              <img src={ditec_graph} alt=" " />
              <img src={ditec_atendimentos} alt=" " className="mb-8 lg:mb-0" />
            </div>

            <div className="head-2">
              <h4 className="title">Por unidades:</h4>
              <div className="desc"></div>
            </div>

            <div className="second">
              {width >= 1024 ? (
                <>
                  <img src={ditec_por_unidades_2019} alt=" " />
                  <img src={ditec_por_unidades_2020} alt=" " />
                  <img src={ditec_por_unidades_2021} alt=" " />
                  <img src={ditec_por_unidades_2022} alt=" " />
                </>
              ) : (
                <>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={pagination}
                    modules={[Pagination, FreeMode]}
                    className="carousel-atendimento-mobo"
                  >
                    <SwiperSlide>
                      <img src={ditec_por_unidades_2019} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={ditec_por_unidades_2020} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={ditec_por_unidades_2021} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={ditec_por_unidades_2022} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="table-data diretorias">
          <div className="head">
            <h3 className="title">PRESI</h3>
            <div className="desc">
              <p>Quantidade de atendimento da diretoria por ano:</p>
            </div>
          </div>

          <div className="body">
            <div className="first flex flex-col-reverse lg:flex-row items-center lg:items-start justify-start lg:space-x-6">
              <img src={presi_graph} alt=" " />
              <img src={presi_atendimentos} alt=" " className="mb-8 lg:mb-0" />
            </div>

            <div className="head-2">
              <h4 className="title">Por unidades:</h4>
              <div className="desc"></div>
            </div>

            <div className="second">
              {width >= 1024 ? (
                <>
                  <img src={presi_por_unidades_2019} alt=" " />
                  <img src={presi_por_unidades_2020} alt=" " />
                  <img src={presi_por_unidades_2021} alt=" " />
                  <img src={presi_por_unidades_2022} alt=" " />
                </>
              ) : (
                <>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={pagination}
                    modules={[Pagination, FreeMode]}
                    className="carousel-atendimento-mobo"
                  >
                    <SwiperSlide>
                      <img src={presi_por_unidades_2019} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={presi_por_unidades_2020} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={presi_por_unidades_2021} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={presi_por_unidades_2022} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="table-data diretorias">
          <div className="head">
            <h3 className="title">DAF</h3>
            <div className="desc">
              <p>Quantidade de atendimentos por unidade de cada diretoria:</p>
            </div>
          </div>

          <div className="body">
            <div className="first flex flex-col-reverse lg:flex-row items-center lg:items-start justify-start lg:space-x-6">
              <img src={daf_graph} alt=" " />
              <img src={daf_atendimentos} alt=" " className="mb-8 lg:mb-0" />
            </div>

            <div className="head-2">
              <h4 className="title">Por unidades:</h4>
              <div className="desc"></div>
            </div>

            <div className="second">
              {width >= 1024 ? (
                <>
                  <img src={daf_por_unidades_2019} alt=" " />
                  <img src={daf_por_unidades_2020} alt=" " />
                  <img src={daf_por_unidades_2021} alt=" " />
                  <img src={daf_por_unidades_2022} alt=" " />
                </>
              ) : (
                <>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={pagination}
                    modules={[Pagination, FreeMode]}
                    className="carousel-atendimento-mobo"
                  >
                    <SwiperSlide>
                      <img src={daf_por_unidades_2019} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={daf_por_unidades_2020} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={daf_por_unidades_2021} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={daf_por_unidades_2022} alt=" " className="mx-auto block max-w-full" />
                    </SwiperSlide>
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
