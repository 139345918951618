import { ChildrenProp } from 'types';
import { BasicProvider, IconProvider } from 'contexts';

export function AppProvider(props: ChildrenProp) {
  const providers = [BasicProvider, IconProvider];
  const { children } = props;

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
