import './style.scss';

export interface IframeYTInterface {
  id: string;
  visible: boolean;
  width?: string;
  height?: string;
  className?: string;
  [key: string]: any;
}

export function IframeYT({ id, visible, width, height, className, ...rest }: IframeYTInterface) {
  if (!visible) {
    return null;
  }

  return (
    <iframe
      className={`iframe-yt ${className || ''}`}
      style={{ width: !!width ? width : '100%', height: !!height ? height : '315px' }}
      src={`https://www.youtube-nocookie.com/embed/${id}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      {...rest}
    ></iframe>
  );
}
