import { useMemo } from 'react';

import { useBasic } from 'contexts';
import { ContentBox, Hero, LinkButton, NucleosDeAtuacao } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-publicacoes.jpg';
import ImageSebrae50 from 'assets/images/publicacoes/sebrae_50anos.png';
import Image15Anos from 'assets/images/publicacoes/15_anos_lei_geral.png';
import ImageEleicoes from 'assets/images/publicacoes/eleicoes_2022.png';
import ImageAtlas from 'assets/images/publicacoes/atlas.png';

export default function PublicacoesPage() {
  const { width } = useBasic();
  const colorTheme = '#FFED69';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Publicações"
        description="Acesse na íntegra documentos relevantes que foram produzidos no ultimo quadriênio."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-12 lg:pt-[72px] space-y-12 lg:space-y-[80px]" colorTheme={colorTheme} watermark>
        <div className="flex flex-col lg:flex-row items-start lg:justify-between">
          <img src={ImageSebrae50} alt=" " className="w-full lg:w-[48%] block mb-[18px] lg:mb-0" />
          <div className="w-full lg:w-[52%] lg:p-[18px] text-center lg:text-left space-y-4 text-lg text-rich-black font-campuni-regular">
            <h2 className="font-campuni-bold font-normal text-3xl lg:text-5xl leading-snug lg:leading-none">Sebrae 50 anos</h2>

            <p>
              Esse livro conta a trajetória de 50 anos do Sebrae, uma instituição que, desde seu início, em 5 de julho de 1972,
              teve como propósito servir ao Brasil e à sua gente por meio da geração de trabalho, emprego e renda, buscando
              reduzir as desigualdades sociais e regionais.
            </p>

            <p>
              A iniciativa enfatiza as ações atuais nos três pilares de atuação: aprimorar a gestão empresarial, desenvolver um
              ambiente de negócios saudável e inovador para os pequenos negócios e promover a cultura empreendedora.
            </p>

            <p>
              Apresenta também a evolução desde a fundação em 1972 até os dias de hoje, com um olhar para o futuro, voltado para
              os novos desafios do empreendedorismo no país.
            </p>

            <p>Nos próximos 50 anos, o Sebrae continuará sendo a força do empreendedor brasileiro.</p>

            <p>
              O livro traz, ainda, uma homenagem aos donos de pequenos negócios, com uma série de fotografias de homens e mulheres
              que trabalham duro para fazer o Brasil andar, no comércio, na prestação de serviços, na indústria e na agropecuária,
              em todas as regiões.
            </p>

            <p>
              <LinkButton
                colorTheme={colorTheme}
                url={'https://bis.sebrae.com.br/bis/conteudoPublicacao.zhtml?id=31375'}
                colorText="#000326"
                className="link-download-orcamento !h-auto !mt-0 lg:!mt-4"
                download
              >
                Faça o download
              </LinkButton>
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row-reverse items-start lg:justify-between">
          <img src={Image15Anos} alt=" " className="w-full lg:w-[48%] block mb-[18px] lg:mb-0" />
          <div className="w-full lg:w-[52%] lg:p-[18px] lg:pt-[25px] text-center lg:text-left space-y-4 text-lg text-rich-black font-campuni-regular">
            <h2 className="font-campuni-bold font-normal text-3xl lg:text-5xl leading-[38px] lg:leading-none">
              15 Anos da Lei Geral <br />
              das Micro e <br />
              Pequenas Empresas:
            </h2>
            <h3 className="text-[20px] lg:text-[28px] leading-[38px] lg:leading-loose font-campuni-bold font-normal block !mt-0 lg:!mt-1">
              O estatuto que mudou a história do Brasil
            </h3>

            <p>
              Esse livro é uma homenagem aos milhões de empreendedores e empreendedoras que descobriram suas vocações em meio à
              vigência da Lei Geral, muitos dos quais estão retratados em nossas páginas.
            </p>

            <p>
              Trazemos também imagens de obras do Centro Sebrae de Referência do Artesanato Brasileiro (Crab), no Rio de Janeiro.
            </p>

            <p>
              <LinkButton
                colorTheme={colorTheme}
                url={'https://bis.sebrae.com.br/bis/conteudoPublicacao.zhtml'}
                colorText="#000326"
                className="link-download-orcamento !h-auto !mt-0 lg:!mt-4"
              >
                Acesse o conteúdo
              </LinkButton>
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-start lg:justify-between">
          <img src={ImageEleicoes} alt=" " className="w-full lg:w-[48%] block mb-[18px] lg:mb-0" />
          <div className="w-full lg:w-[52%] lg:p-[18px] lg:pt-[25px] text-center lg:text-left space-y-4 text-lg text-rich-black font-campuni-regular">
            <h2 className="font-campuni-bold font-normal text-3xl lg:text-5xl leading-[38px] lg:leading-none">
              Guia Eleições 2022 -
            </h2>
            <h3 className="text-[20px] lg:text-[28px] leading-[38px] lg:leading-loose font-campuni-bold font-normal block !mt-0 lg:!mt-1">
              O Brasil vota nos pequenos negócios - Propostas aos candidatos
            </h3>

            <p>
              Essa publicação busca oferecer subsídios aos candidatos para que possam ter uma visão ampliada do protagonismo das
              MPE e de como é possível contribuir para a melhoria do ambiente de negócios e para a ampliação da participação das
              pequenas empresas na economia, que atualmente respondem por aproximadamente 30% do PIB.
            </p>

            <p>
              No ano em que completa 50 anos, a instituição reafirma seu permanente compromisso e sua disposição em contribuir
              para o desenvolvimento do nosso Brasil.
            </p>

            <p>
              <LinkButton
                colorTheme={colorTheme}
                url={'https://guiadocandidato2022.sebrae.com.br/storage/guia-em-video/SEBRAE_NA_Guia_Completo_PRESIDENTE.pdf'}
                colorText="#000326"
                className="link-download-orcamento !h-auto !mt-0 lg:!mt-4"
                download
              >
                Faça o download
              </LinkButton>
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row-reverse items-center lg:justify-between">
          <img src={ImageAtlas} alt=" " className="w-full lg:w-[48%] block mb-[18px] lg:mb-0" />
          <div className="w-full lg:w-[52%] lg:p-[18px] lg:pt-[25px] text-center lg:text-left space-y-4 text-lg text-rich-black font-campuni-regular">
            <h2 className="font-campuni-bold font-normal text-3xl lg:text-5xl leading-snug lg:leading-none">
              Atlas dos Pequenos Negócios
            </h2>

            <p>
              Estudo aprofundado sobre micro e pequenas empresas e microempreendedores individuais mostra que a renda gerada pelo
              setor no Brasil é de R$ 420 bilhões ao ano.
            </p>

            <p>Confira o Atlas dos Pequenos Negócios - Versão resumida e Infográfico:</p>

            <p className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4">
              <LinkButton
                colorTheme={colorTheme}
                url={'https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/Sebrae%2050+50/Not%C3%ADcias/PRESSKIT%2050%20ANOS.pdf'}
                colorText="#000326"
                className="link-download-orcamento !h-auto !mt-0"
                download
              >
                Versão resumida
              </LinkButton>
              <LinkButton
                colorTheme={colorTheme}
                url={
                  'https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/Sebrae%2050+50/Not%C3%ADcias/atlas-sebrae-jun-2022%20(3).pdf'
                }
                colorText="#000326"
                className="link-download-orcamento !h-auto !mt-4 lg:!mt-0"
                download
              >
                Infográfico
              </LinkButton>
            </p>
          </div>
        </div>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
