import { IoLogoWhatsapp } from 'react-icons/io';

export const icons: { [key: string]: any } = {
  conteudo: (
    <>
      <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8064 0.641025C25.8064 0.286997 25.5176 0 25.1613 0H7.09677C3.17733 0 0 3.15697 0 7.05128V42.9487C0 46.843 3.17733 50 7.09677 50H32.9032C36.8227 50 40 46.843 40 42.9487V17.6848C40 17.3307 39.7112 17.0437 39.3548 17.0437H27.7419C26.673 17.0437 25.8064 16.1828 25.8064 15.1207V0.641025ZM27.7419 25.641C28.8109 25.641 29.6774 26.502 29.6774 27.5641C29.6774 28.6262 28.8109 29.4872 27.7419 29.4872H12.2581C11.1891 29.4872 10.3226 28.6262 10.3226 27.5641C10.3226 26.502 11.1891 25.641 12.2581 25.641H27.7419ZM27.7419 35.8974C28.8109 35.8974 29.6774 36.7584 29.6774 37.8205C29.6774 38.8826 28.8109 39.7436 27.7419 39.7436H12.2581C11.1891 39.7436 10.3226 38.8826 10.3226 37.8205C10.3226 36.7584 11.1891 35.8974 12.2581 35.8974H27.7419Z"
          fill="currentColor"
        />
        <path
          d="M29.6774 1.47214C29.6774 0.999076 30.1746 0.698706 30.545 0.995862C30.8571 1.24615 31.1375 1.53938 31.3768 1.87064L39.1527 12.6345C39.3298 12.8795 39.138 13.1976 38.8344 13.1976H30.3226C29.9663 13.1976 29.6774 12.9106 29.6774 12.5566V1.47214Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  promocao: (
    <>
      <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M49.0566 0H26.3218C25.8018 0 25.3024 0.199057 24.9345 0.55283L0.69366 23.8613C0.249208 24.2887 0 24.867 0 25.4717C0 26.0764 0.249208 26.6547 0.69366 27.0821L23.8346 49.333C24.2791 49.7604 24.8805 50 25.5094 50C26.1383 50 26.7398 49.7604 27.1842 49.333L51.4251 26.0245C51.793 25.6708 52 25.1896 52 24.6906V2.83019C52 1.26698 50.6814 0 49.0566 0ZM26.4906 22.6415C30.2768 22.6415 33.3585 25.1802 33.3585 28.3019C33.3585 30.8613 31.2863 33.0264 28.4528 33.7236V35.8491H24.5283V33.7236C21.6948 33.0264 19.6226 30.8613 19.6226 28.3019H23.5472C23.5472 29.3066 24.9227 30.1887 26.4906 30.1887C28.0584 30.1887 29.434 29.3066 29.434 28.3019C29.434 27.2972 28.0584 26.4151 26.4906 26.4151C22.7044 26.4151 19.6226 23.8764 19.6226 20.7547C19.6226 18.1953 21.6948 16.0302 24.5283 15.333V13.2075H28.4528V15.333C31.2863 16.0302 33.3585 18.1953 33.3585 20.7547H29.434C29.434 19.75 28.0584 18.8679 26.4906 18.8679C24.9227 18.8679 23.5472 19.75 23.5472 20.7547C23.5472 21.7594 24.9227 22.6415 26.4906 22.6415ZM43.1698 11.3208C41.5451 11.3208 40.2264 10.0528 40.2264 8.49057C40.2264 6.92736 41.5451 5.66038 43.1698 5.66038C44.7946 5.66038 46.1132 6.92736 46.1132 8.49057C46.1132 10.0528 44.7946 11.3208 43.1698 11.3208Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  'central-de-producao': (
    <>
      <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M37.1429 4.7619C37.1429 2.13143 35.0647 0 32.5 0H4.64286C2.07814 0 0 2.13143 0 4.7619V35.2381C0 37.8686 2.07814 40 4.64286 40H32.5C35.0647 40 37.1429 37.8686 37.1429 35.2381V4.7619Z"
          fill="currentColor"
        />
        <path
          d="M48.8989 4.76196C48.494 4.76196 48.0938 4.84387 47.7186 5.00196L40.8574 7.89911V32.101L47.7186 34.9982C48.0938 35.1562 48.4949 35.2382 48.8989 35.2382C50.6121 35.2382 52.0003 33.8143 52.0003 32.0572V7.94292C52.0003 6.18577 50.6121 4.76196 48.8989 4.76196Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  branding: (
    <>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M37.3337 22.4C29.0868 22.4 22.4004 29.0855 22.4004 37.3334C22.4004 45.5812 29.0868 52.2667 37.3337 52.2667C45.5807 52.2667 52.2671 45.5812 52.2671 37.3334C52.2671 29.0855 45.5807 22.4 37.3337 22.4ZM37.3337 35.4667C40.9355 35.4667 43.8671 37.9783 43.8671 41.0667C43.8671 43.5988 41.8959 45.7408 39.2004 46.4306V48.5334H35.4671V46.4306C32.7716 45.7408 30.8004 43.5988 30.8004 41.0667H34.5337C34.5337 42.0607 35.8423 42.9334 37.3337 42.9334C38.8252 42.9334 40.1337 42.0607 40.1337 41.0667C40.1337 40.0727 38.8252 39.2 37.3337 39.2C33.732 39.2 30.8004 36.6884 30.8004 33.6C30.8004 31.0679 32.7716 28.9259 35.4671 28.2362V26.1334H39.2004V28.2362C41.8959 28.9259 43.8671 31.0679 43.8671 33.6H40.1337C40.1337 32.606 38.8252 31.7334 37.3337 31.7334C35.8423 31.7334 34.5337 32.606 34.5337 33.6C34.5337 34.594 35.8423 35.4667 37.3337 35.4667Z"
          fill="currentColor"
        />
        <path
          d="M41.9998 3.73328V6.53328H38.2665V3.73328H34.5332V6.53328H30.7998V3.73328H27.0665V6.53328H23.3332V3.73328H19.5998V6.53328H15.8665V3.73328H12.1332V6.53328H8.39984V3.73328H4.6665V51.3333H8.39984V48.5333H12.1332V51.3333H15.8665V48.5333H19.5998V51.3333H25.015C21.1305 47.9126 18.6665 42.9155 18.6665 37.3333C18.6665 27.0237 27.0236 18.6666 37.3332 18.6666C40.3581 18.6666 43.2066 19.4021 45.7332 20.6798V3.73328H41.9998ZM22.3998 20.5333H10.2665V17.7333H22.3998V20.5333ZM29.8665 13.9999H10.2665V11.1999H29.8665V13.9999ZM40.1332 13.9999H33.5998V11.1999H40.1332V13.9999Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  'redes-sociais': (
    <>
      <IoLogoWhatsapp className="text-[48px]" />
    </>
  ),
  atendimento: (
    <>
      <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.42791 15.4272C2.30164 16.2236 0 18.9326 0 22.1519V29.7468C0 33.5919 3.28325 36.7089 7.33333 36.7089H14C15.1046 36.7089 16 35.8588 16 34.8101V17.0886C16 16.04 15.1046 15.1899 14 15.1899H9.50621C10.6506 8.93677 17.3563 3.79747 26 3.79747C34.6437 3.79747 41.3494 8.93677 42.4938 15.1899H38C36.8954 15.1899 36 16.04 36 17.0886V34.8101C36 35.8588 36.8954 36.7089 38 36.7089H42.4347C41.5318 40.3376 38.0981 43.038 34 43.038H30.9457C30.1545 41.1824 28.2383 39.8734 26 39.8734C23.0545 39.8734 20.6667 42.1403 20.6667 44.9367C20.6667 47.7331 23.0545 50 26 50C28.2383 50 30.1545 48.691 30.9457 46.8354H34C40.3994 46.8354 45.6904 42.3301 46.5458 36.4782C49.6854 35.6903 52 32.9752 52 29.7468V22.1519C52 18.9326 49.6984 16.2236 46.5721 15.4272C45.5549 6.55593 36.4699 0 26 0C15.5301 0 6.44514 6.55592 5.42791 15.4272Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  'projeto-especial': (
    <>
      <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.1713 44.7764C19.5572 45.1316 20.1423 44.7446 19.9974 44.2327L13.4424 21.0715C13.3203 20.6402 12.9503 20.3333 12.5155 20.3004C11.4547 20.2201 10.3943 20.1303 9.33459 20.0311L0.565521 19.2095C0.156128 19.1712 -0.132042 19.6136 0.0617797 19.9872C4.80498 29.1298 11.6352 37.8413 19.1713 44.7764Z"
          fill="currentColor"
        />
        <path
          d="M25.6023 49.6725C25.6552 49.8594 25.8111 50 26 50C26.1889 50 26.3448 49.8594 26.3977 49.6725L34.4213 21.3227C34.5213 20.9694 34.2541 20.6197 33.8973 20.6354C28.6349 20.8683 23.3652 20.8683 18.1027 20.6355C17.7459 20.6197 17.4787 20.9694 17.5787 21.3227L25.6023 49.6725Z"
          fill="currentColor"
        />
        <path
          d="M32.0026 44.2327C31.8577 44.7446 32.4428 45.1316 32.8287 44.7764C40.3649 37.8412 47.195 29.1298 51.9382 19.9872C52.132 19.6136 51.8439 19.1712 51.4345 19.2095L42.6654 20.0311C41.6057 20.1303 40.5453 20.2201 39.4845 20.3004C39.0497 20.3333 38.6797 20.6402 38.5577 21.0715L32.0026 44.2327Z"
          fill="currentColor"
        />
        <path
          d="M51.4999 15.1649C51.8964 15.1278 52.1072 14.6642 51.8811 14.3264L43.4572 1.73852C42.7287 0.649981 41.5276 0 40.2444 0H33.767C33.3972 0 33.1454 0.386314 33.2853 0.739074L39.1568 15.5486C39.3275 15.9791 39.7479 16.2475 40.1977 16.2109C40.9028 16.1534 41.6075 16.0917 42.3121 16.0257L51.4999 15.1649Z"
          fill="currentColor"
        />
        <path
          d="M29.0312 0.660001C28.8696 0.260336 28.4907 0 28.0706 0H23.9295C23.5093 0 23.1304 0.260337 22.9688 0.660001L16.835 15.8308C16.6966 16.173 16.9288 16.5531 17.2881 16.5711C23.0926 16.8606 28.9074 16.8606 34.7119 16.5711C35.0712 16.5531 35.3034 16.173 35.165 15.8308L29.0312 0.660001Z"
          fill="currentColor"
        />
        <path
          d="M18.7147 0.739075C18.8546 0.386315 18.6028 0 18.233 0H11.7556C10.4724 0 9.2713 0.649983 8.54284 1.73853L0.118862 14.3264C-0.107177 14.6642 0.103606 15.1278 0.500113 15.1649L9.68792 16.0257C10.3924 16.0917 11.0972 16.1534 11.8023 16.2109C12.2521 16.2475 12.6726 15.9791 12.8432 15.5486L18.7147 0.739075Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  publicacoes: (
    <>
      <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7368 3.13591C17.8017 -0.402236 9.32008 -0.980109 1.81091 1.57392C0.717184 1.94592 0 2.84664 0 3.84828V28.3962C0 30.0405 1.95312 31.1649 3.74863 30.5542C9.89785 28.4627 16.8434 28.9359 22.5225 31.8333L25.3337 33.2675C25.5481 33.3769 25.7774 33.4247 26 33.4214C26.2226 33.4247 26.4519 33.3769 26.6663 33.2675L29.4775 31.8333C35.1566 28.9359 42.1021 28.4627 48.2514 30.5542C50.0469 31.1649 52 30.0405 52 28.3962V3.84828C52 2.84664 51.2828 1.94592 50.1891 1.57392C42.6799 -0.980109 34.1983 -0.402236 27.2632 3.13591L26 3.78039L24.7368 3.13591ZM27.8571 8.16637C27.8571 7.29424 27.0257 6.58724 26 6.58724C24.9743 6.58724 24.1429 7.29424 24.1429 8.16637V28.1687C24.1429 29.0409 24.9743 29.7479 26 29.7479C27.0257 29.7479 27.8571 29.0409 27.8571 28.1687V8.16637Z"
          fill="currentColor"
        />
        <path
          d="M3.03293 34.5742C8.04439 32.0885 14.2413 32.0885 19.2528 34.5742L21.945 35.9096C24.4508 37.1524 27.5492 37.1524 30.055 35.9096L32.7472 34.5742C37.7587 32.0885 43.9556 32.0885 48.9671 34.5742L49.2215 34.7004C50.1074 35.1398 50.4067 36.1068 49.8899 36.8601C49.3731 37.6134 48.2359 37.8679 47.35 37.4284L47.0955 37.3022C43.2406 35.3901 38.4737 35.3901 34.6187 37.3022L31.9265 38.6376C28.2643 40.4541 23.7357 40.4541 20.0735 38.6376L17.3813 37.3022C13.5263 35.3901 8.75942 35.3901 4.90446 37.3022L4.65005 37.4284C3.76409 37.8679 2.62693 37.6134 2.11013 36.8601C1.59332 36.1068 1.89257 35.1398 2.77853 34.7004L3.03293 34.5742Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  administrativo: (
    <>
      <svg width="56" height="54" viewBox="0 0 56 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47 27.7205H46.25V28.4705V47.3284H37.75V18.6666V17.9166H37H33H32.25V18.6666V47.3284H23.75V34.3529V33.6029H23H19H18.25V34.3529V47.3284H9.75V23.5686V22.8186H9H5H4.25V23.5686V50.0392C4.25 51.5497 5.49586 52.75 7 52.75H49C50.5041 52.75 51.75 51.5497 51.75 50.0392V28.4705V27.7205H51H47Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M49 13.0147C48.2751 13.0147 47.5925 13.1563 46.9659 13.3952L40.6112 8.05549C40.695 7.69406 40.75 7.30938 40.75 6.90196C40.75 3.76597 38.1621 1.25 35 1.25C31.8379 1.25 29.25 3.76597 29.25 6.90196C29.25 7.70612 29.4345 8.45675 29.7386 9.13354L21.9265 18.9822C21.6326 18.9302 21.3238 18.8971 21 18.8971C20.1876 18.8971 19.4293 19.0762 18.7441 19.3711L12.6612 14.6854C12.7155 14.3941 12.75 14.0872 12.75 13.7647C12.75 10.6287 10.1621 8.11275 7 8.11275C3.83786 8.11275 1.25 10.6287 1.25 13.7647C1.25 16.9007 3.83786 19.4167 7 19.4167C7.81238 19.4167 8.57069 19.2375 9.25588 18.9426L15.3388 23.6283C15.2845 23.9196 15.25 24.2265 15.25 24.549C15.25 27.685 17.8379 30.201 21 30.201C24.1621 30.201 26.75 27.685 26.75 24.549C26.75 23.7449 26.5656 22.9942 26.2614 22.3174L34.0735 12.4688C34.3674 12.5208 34.6762 12.5539 35 12.5539C35.7249 12.5539 36.4075 12.4123 37.0341 12.1734L43.3888 17.5131C43.305 17.8746 43.25 18.2592 43.25 18.6667C43.25 21.8027 45.8379 24.3186 49 24.3186C52.1621 24.3186 54.75 21.8027 54.75 18.6667C54.75 15.5307 52.1621 13.0147 49 13.0147Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </>
  ),
  bi: (
    <>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M51.3334 40.1333V11.2C51.3334 9.13827 49.6618 7.46667 47.6 7.46667H8.40003C6.3383 7.46667 4.6667 9.13827 4.6667 11.2V40.1333H1.8667V43.8667C1.8667 45.9284 3.5383 47.6 5.60003 47.6H43.8667V43.8667H47.6V47.6H50.4C52.4618 47.6 54.1334 45.9284 54.1334 43.8667V40.1333H51.3334ZM8.40003 40.1333V11.2H47.6V40.1333H8.40003Z"
          fill="currentColor"
        />
        <path
          d="M27.9999 14.9333C30.5777 14.9333 32.6665 17.0221 32.6665 19.6C32.6665 22.1779 30.5777 24.2667 27.9999 24.2667C25.422 24.2667 23.3332 22.1779 23.3332 19.6C23.3332 17.0221 25.422 14.9333 27.9999 14.9333ZM20.5332 36.4C20.5332 31.7333 22.5473 27.0667 27.9999 27.0667C33.4524 27.0667 35.4665 31.7333 35.4665 36.4H20.5332Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
  'quem-faz-a-ucom': (
    <>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.2002 29.8667H29.8669V41.0667C29.8669 42.0971 30.7031 42.9333 31.7335 42.9333H38.2669V39.2H33.6002V16.8H38.2669V13.0667H31.7335C30.7031 13.0667 29.8669 13.9029 29.8669 14.9333V26.1333H25.2002V29.8667Z"
          fill="currentColor"
        />
        <path
          d="M13.9999 26.1333C18.1236 26.1333 21.4665 22.7903 21.4665 18.6666C21.4665 14.5429 18.1236 11.2 13.9999 11.2C9.87614 11.2 6.5332 14.5429 6.5332 18.6666C6.5332 22.7903 9.87614 26.1333 13.9999 26.1333Z"
          fill="currentColor"
        />
        <path
          d="M27.0659 45.7334V41.0667C27.0659 33.6 19.6693 28.9333 13.9993 28.9333C8.32928 28.9333 0.932617 33.6 0.932617 41.0667V45.7334H27.0659Z"
          fill="currentColor"
        />
        <path
          d="M46.6667 14C49.244 14 51.3333 11.9106 51.3333 9.33329C51.3333 6.75596 49.244 4.66663 46.6667 4.66663C44.0893 4.66663 42 6.75596 42 9.33329C42 11.9106 44.0893 14 46.6667 14Z"
          fill="currentColor"
        />
        <path
          d="M54.1335 26.1334H39.2002C39.2002 21.4667 41.2143 16.8 46.6669 16.8C52.1194 16.8 54.1335 21.4667 54.1335 26.1334Z"
          fill="currentColor"
        />
        <path
          d="M46.6667 39.2C49.244 39.2 51.3333 37.1107 51.3333 34.5334C51.3333 31.956 49.244 29.8667 46.6667 29.8667C44.0893 29.8667 42 31.956 42 34.5334C42 37.1107 44.0893 39.2 46.6667 39.2Z"
          fill="currentColor"
        />
        <path
          d="M54.1335 51.3333H39.2002C39.2002 46.6667 41.2143 42 46.6669 42C52.1194 42 54.1335 46.6667 54.1335 51.3333Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
};
