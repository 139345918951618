import './style.scss';

export interface TitleAreaInterface {
  className?: string;
  children: string | React.ReactNode;
}

export function TitleArea({ className, children }: TitleAreaInterface) {
  return (
    <h2
      className={`title-area px-4 py-8 lg:py-4 text-rich-black text-[32px] lg:text-5xl leading-none block font-normal font-campuni-bold ${
        className || ''
      }`}
    >
      {children}
    </h2>
  );
}
