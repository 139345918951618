import { useMemo } from 'react';

import { useBasic } from 'contexts';
import { ContentBox, FlexibleBox, Hero, LinkButton, NucleosDeAtuacao, TitleArea } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-bi.jpg';
import ImagePipefy from 'assets/images/bi/pipefy.png';
import ImageEad from 'assets/images/bi/ead.png';
import ImageOQueFaz from 'assets/images/bi/o-que-faz.png';
import ImageHotsite5050 from 'assets/images/bi/hotsite-50_50.png';
import ImageASN from 'assets/images/bi/asn.png';
import ImageCoronavirus from 'assets/images/bi/coronavirus.png';
import ImagePSJ from 'assets/images/bi/psj.png';
import ImagePersonagens from 'assets/images/bi/personagens.png';
import ImageAnonovo from 'assets/images/bi/ano-novo.png';
import ImageEmpreendedorismo from 'assets/images/bi/empreendedorismo.png';
import ImageIndicacoes from 'assets/images/bi/indicacoes.png';
import ImageHotsiteLGPD from 'assets/images/bi/hotsite-lgpd.png';
import ImageHotsiteCompre from 'assets/images/bi/hotsite-compre-pequeno.png';
import ImageDataSebrae from 'assets/images/bi/data-sebrae.png';
import ImageNovaIntranet from 'assets/images/bi/nova-intranet.png';
import ImageAppSebrae from 'assets/images/bi/app-sebrae.png';
import ImageHalloween from 'assets/images/bi/halloween.png';
import ImageMercadoAzul from 'assets/images/bi/mercado-azul.png';

export default function BIPage() {
  const { width } = useBasic();
  const colorTheme = '#F4455A';

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="BI/Digital"
        description="Identifica e automatiza os processos, armazena e apresenta análise dos dados para controle da produção e gastos da Unidade de Comunicação. Faz a análise do monitoramento das redes sociais do Sebrae Nacional."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-8 lg:pt-20 space-y-8 lg:space-y-16" colorTheme={colorTheme} watermark>
        <FlexibleBox colorTheme={colorTheme} title={<>Pipefy</>} position={'center'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>Ferramenta de gestão para organizar os fluxos de trabalho e aperfeiçoar constantemente as entregas.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImagePipefy} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.pipefy.com/pt-br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImagePipefy} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.pipefy.com/pt-br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>EAD Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                A página de cursos EAD Sebrae está entre as mais acessadas do portal. É dedicada à capacitação de empreendedores e
                conta com cursos, trilhas, jogos e e-books. <br />
                Tudo está disponível online, de forma gratuita e com certificação.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageEad} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/cursosonline'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageEad} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/cursosonline'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>O que o Sebrae faz</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Uma dúvida recorrente dos empreendedores é "O que o Sebrae faz?". Por isso, foi criado um site com informações,
                testemunhos e histórias de quem conhece e já contou com o apoio do Sebrae para empreender.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageOQueFaz} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.oqueosebraefaz.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageOQueFaz} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.oqueosebraefaz.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Hotsite 50+50</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Site responsável por apresentar os principais feitos do Sebrae nos últimos 50 anos e os planos da entidade para o
                futuro do empreendedorismo brasileiro.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageHotsite5050} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/sites/PortalSebrae/sebrae50mais50'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageHotsite5050} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/sites/PortalSebrae/sebrae50mais50'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>ASN</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                No dia 1º de junho, Dia da Imprensa, foi lançada a nova Agência Sebrae de Notícias, um canal de comunicação
                multimídia com uma interface mais acessível para o jornalista e com informações de todos as unidades do Sebrae.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageASN} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageASN} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Coronavírus</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Com a pandemia, foi criado, em caráter emergencial, um site com cursos, dicas, sugestões e informações para
                auxiliar os microempreendedores a superarem o período delicado na economia e saúde do país.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageCoronavirus} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/coronavirus'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageCoronavirus} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/coronavirus'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Prêmio Sebrae de Jornalismo</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Desde que foi criado, o PSJ valoriza os profissionais de imprensa que contribuem para o fortalecimento do
                empreendedorismo. Por isso, nessa edição, o novo site foi criado de forma mais informativa e estratégica, com
                novas funcionalidades.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImagePSJ} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://premiosebraejornalismo.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImagePSJ} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://premiosebraejornalismo.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Personagens Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Para apresentar histórias reais de clientes que contaram com o apoio do Sebrae para empreender, foi criado o site
                Personagens Sebrae.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImagePersonagens} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://personagenssebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImagePersonagens} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://personagenssebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Ano Novo</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                A campanha #MovidosPelaForça contou com um site com soluções de acordo com a jornada empreendedora, incluindo
                dicas de inovação e orientações sobre como realizar os planos no próximo ano e como vencer os desafios de
                empreender.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageAnonovo} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.facebook.com/watch/?v=883526118784179'}>
                    Acesse o vídeo
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageAnonovo} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.facebook.com/watch/?v=883526118784179'}>
                    Acesse o vídeo
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Empreendedorismo Feminino</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Por acreditarmos na força das mulheres nos negócios, criamos o Portal Delas para impulsionar ainda mais o
                empreendedorismo feminino, orientando e inspirando as empreendedoras com conteúdo exclusivo e informativo.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageEmpreendedorismo} alt=" " className="max-w-full block mb-6" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.sebrae.com.br/sites/PortalSebrae/empreendedorismofeminino'}
                  >
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageEmpreendedorismo} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.sebrae.com.br/sites/PortalSebrae/empreendedorismofeminino'}
                  >
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Indicações Geográficas</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Para apresentar os produtos e serviços que "Só Tem no Brasil", foi criado o site das Indicações Geográficas, que
                tem histórias reais de quem empreende com insumos bem brasileiros.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageIndicacoes} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/origens'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageIndicacoes} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/origens'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Hotsite LGPD</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Com a vigência da Lei Geral de Proteção de Dados, foi criado um site para sanar as dúvidas dos empreendedores
                sobre a importância de se adequar às determinações.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageHotsiteLGPD} alt=" " className="max-w-full block mb-6" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.sebrae.com.br/sites/PortalSebrae/lgpd/lgpd,405b4c2a9978a710VgnVCM100000d701210aRCRD'}
                  >
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageHotsiteLGPD} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://www.sebrae.com.br/sites/PortalSebrae/lgpd/lgpd,405b4c2a9978a710VgnVCM100000d701210aRCRD'}
                  >
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Hotsite Compre do Pequeno</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Uma campanha de valorização foi criada para incentivar a população sobre a importância do pequeno negócio e
                estimular a compra no comércio local em um momento tão delicado como o da pandemia.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageHotsiteCompre} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/compredopequeno'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageHotsiteCompre} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/compredopequeno'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Data Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                O ambiente de negócios do Sebrae ganhou uma nova home e duas novas áreas, MapInova e PIB, sendo esse último
                apresentado de uma forma mais simples e direta para o usuário, incluindo dados e contexto histórico.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageDataSebrae} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://datasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageDataSebrae} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://datasebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Nova intranet</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>Foi reformulada a intranet do Sistema Sebrae para trazer mais informações e agilidade aos usuários.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageNovaIntranet} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://novoconexao.sebrae.com.br/portal/p/1/home'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageNovaIntranet} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://novoconexao.sebrae.com.br/portal/p/1/home'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>App Sebrae</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                O app do Sebrae ganhou um nova versão com um layout moderno, seguindo o brandbook, e com novas funcionalidades
                para facilitar o acesso do usuário.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              <div className="w-box-full flex-col flex items-center justify-center">
                <img src={ImageAppSebrae} alt=" " className="max-w-[100px] w-full lg:w-auto lg:max-w-full block mb-6 mx-auto" />

                <div className="w-full flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-6">
                  <LinkButton colorTheme={colorTheme} url={'https://apps.apple.com/br/app/sebrae/id1482839375'}>
                    Versão IOS
                  </LinkButton>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://play.google.com/store/apps/details?id=br.com.sebrae.napalmadamao&hl=pt_BR&gl=US'}
                  >
                    Versão Android
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Halloween</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Em alusão ao Dia das Bruxas, foi lançado um desafio para encontrar os monstros que assombram os empreendedores e
                dicas com soluções para auxiliar os usuários a eliminá-los.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageHalloween} alt=" " className="max-w-full block" />
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageHalloween} alt=" " className="max-w-[250px] w-full block" />
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Mercado azul</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Descrição</strong>
              </p>
              <p>
                Uma vitrine de negócios criada para que as empresas divulguem seus produtos e serviços de forma rápida, fácil e
                gratuita, de forma a facilitar o acesso de clientes aos fornecedores.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-6">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <img src={ImageMercadoAzul} alt=" " className="max-w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://mercadoazul.sebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex-col flex items-center justify-center">
                  <img src={ImageMercadoAzul} alt=" " className="max-w-[250px] w-full block mb-6" />

                  <LinkButton colorTheme={colorTheme} url={'https://mercadoazul.sebrae.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
