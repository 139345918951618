import { BrowserRouter } from 'react-router-dom';

import { AppProvider } from 'contexts';
import MyRoutes from './routes';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <MyRoutes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
