import { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import PATHS from 'routes/paths';
import { icons } from './icons';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';

export interface NucleosDeAtuacaoInterface {
  colorTheme: string;
}

export function NucleosDeAtuacao({ colorTheme }: NucleosDeAtuacaoInterface) {
  const swiperRef = useRef<SwiperType>();

  const routes = PATHS.filter(route => route.id !== 'home');

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  return (
    <section className="nucleos-de-atuacao bg-rich-black" style={{ '--color-theme': colorTheme || '' } as React.CSSProperties}>
      <h2>Núcleos de atuação</h2>

      <div>
        <button onClick={() => swiperRef.current?.slidePrev()} className="btn-prev">
          <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_691_13810)">
              <circle
                cx="23"
                cy="23"
                r="23"
                transform="matrix(1 0 0 -1 4 46)"
                fill="#FAFAFA"
                fillOpacity="0.2"
                shapeRendering="crispEdges"
              />
            </g>
            <path
              d="M25.8523 23.0248L31.45 28.6225C31.7445 28.9187 31.9098 29.3195 31.9098 29.7373C31.9098 30.155 31.7445 30.5558 31.45 30.852C31.303 31.0002 31.1281 31.1179 30.9354 31.1982C30.7427 31.2784 30.536 31.3198 30.3273 31.3198C30.1185 31.3198 29.9118 31.2784 29.7192 31.1982C29.5265 31.1179 29.3516 31.0002 29.2046 30.852L22.5001 24.1475C22.3519 24.0005 22.2342 23.8256 22.154 23.633C22.0737 23.4403 22.0323 23.2336 22.0323 23.0248C22.0323 22.8161 22.0737 22.6094 22.154 22.4167C22.2342 22.224 22.3519 22.0492 22.5001 21.9022L29.2046 15.1186C29.3523 14.972 29.5276 14.8561 29.7202 14.7774C29.9129 14.6987 30.1192 14.6588 30.3273 14.66C30.5354 14.6588 30.7417 14.6987 30.9343 14.7774C31.127 14.8561 31.3022 14.972 31.45 15.1186C31.7445 15.4149 31.9098 15.8156 31.9098 16.2334C31.9098 16.6511 31.7445 17.0519 31.45 17.3482L25.8523 23.0248Z"
              fill="#FAFAFA"
            />
            <defs>
              <filter
                id="filter0_d_691_13810"
                x="0"
                y="0"
                width="54"
                height="54"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_691_13810" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_691_13810" result="shape" />
              </filter>
            </defs>
          </svg>
        </button>

        <button onClick={() => swiperRef.current?.slideNext()} className="btn-next">
          <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_691_13807)">
              <circle
                cx="27"
                cy="23"
                r="23"
                transform="rotate(180 27 23)"
                fill="#FAFAFA"
                fillOpacity="0.2"
                shapeRendering="crispEdges"
              />
            </g>
            <path
              d="M28.1478 23.0248L22.5502 28.6225C22.2557 28.9187 22.0904 29.3195 22.0904 29.7373C22.0904 30.155 22.2557 30.5558 22.5502 30.852C22.6972 31.0002 22.8721 31.1179 23.0647 31.1982C23.2574 31.2784 23.4641 31.3198 23.6729 31.3198C23.8816 31.3198 24.0883 31.2784 24.281 31.1982C24.4737 31.1179 24.6485 31.0002 24.7955 30.852L31.5 24.1475C31.6483 24.0005 31.7659 23.8256 31.8462 23.633C31.9264 23.4403 31.9678 23.2336 31.9678 23.0248C31.9678 22.8161 31.9264 22.6094 31.8462 22.4167C31.7659 22.224 31.6483 22.0492 31.5 21.9022L24.7955 15.1186C24.6478 14.972 24.4726 14.8561 24.2799 14.7774C24.0873 14.6987 23.881 14.6588 23.6729 14.66C23.4648 14.6588 23.2585 14.6987 23.0658 14.7774C22.8731 14.8561 22.6979 14.972 22.5502 15.1186C22.2557 15.4149 22.0904 15.8156 22.0904 16.2334C22.0904 16.6511 22.2557 17.0519 22.5502 17.3482L28.1478 23.0248Z"
              fill="#FAFAFA"
            />
            <defs>
              <filter
                id="filter0_d_691_13807"
                x="0"
                y="0"
                width="54"
                height="54"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_691_13807" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_691_13807" result="shape" />
              </filter>
            </defs>
          </svg>
        </button>

        <Swiper
          pagination={pagination}
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          modules={[Pagination, Navigation, FreeMode]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          }}
        >
          {routes.map((route, index) => (
            <SwiperSlide key={route.id}>
              <Link
                to={route.pathname}
                className={`link-item`}
                style={{ '--color-hover': route.colorHover } as React.CSSProperties}
              >
                {icons[route.id]}
                <span>{route.title}</span>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
