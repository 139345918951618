import { useContext, createContext, useState, useLayoutEffect, useEffect, useMemo } from 'react';

import { ChildrenProp } from 'types';

export interface BasicContextInterface {
  fullLoading: boolean;
  showHeader: boolean;
  showFooter: boolean;
  customClass: string;
  width: number;
  height: number;
  setFullLoading: (value: boolean) => void;
  setShowHeader: (value: boolean) => void;
  setShowFooter: (value: boolean) => void;
  setCustomClass: (value: string) => void;
}

const BasicContext = createContext({} as BasicContextInterface);

function BasicProviderData(): BasicContextInterface {
  const [fullLoading, setFullLoading] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [customClass, setCustomClass] = useState<string>('');
  const [width, height] = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
  }

  return {
    fullLoading,
    showHeader,
    showFooter,
    customClass,
    width,
    height,
    setFullLoading,
    setShowHeader,
    setShowFooter,
    setCustomClass,
  };
}

const BasicProvider = ({ children }: ChildrenProp) => {
  const contextData: BasicContextInterface = BasicProviderData();
  return <BasicContext.Provider value={contextData}>{children}</BasicContext.Provider>;
};

const useBasic = (): BasicContextInterface => {
  const context = useContext(BasicContext);

  if (typeof context === 'undefined') {
    throw new Error('useBasic must be used within an BasicProvider');
  }

  return context;
};

export { BasicProvider, useBasic };
