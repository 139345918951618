import { useCallback, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import { useBasic } from 'contexts';
import { Anchor, ContentBox, FlexibleBox, Hero, IframeYT, LinkButton, NucleosDeAtuacao, TitleArea } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';
import ImageHero from 'assets/images/hero-central-de-producao.jpg';
import ImageYouTube from 'assets/images/central-de-producao/inscritos-youtube.png';

export default function CentralDeProducaoPage() {
  const swiperRef = useRef<SwiperType>();
  const { width } = useBasic();
  const colorTheme = '#FFABAB';

  const [vidVisible, setVidVisible] = useState({
    vid1: false,
  });
  const [videoActive, setVideoActive] = useState(0);

  const onUpdateState = useCallback((state: boolean, id: string) => {
    setVidVisible(prevstate => ({ ...prevstate, [id]: state }));
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  const videos = [
    {
      id: 't4ZwfrZswxE',
      title: 'Rally dos Sertões - Casos de Sucesso',
    },
    {
      id: 'TFG9NDK_18c',
      title: 'Startup Summit',
    },
    {
      id: 'eOUFuBT91xg',
      title: 'Mercado Digital',
    },
    {
      id: 'HGn84egd6Es',
      title: 'Futuro do Empreendedorismo',
    },
    {
      id: '6kd_6ujswNA',
      title: '1º episódio - O futuro é hoje',
    },
    {
      id: 'F7N_zAp4mIo',
      title: 'Inova Amazônia institucional',
    },
    {
      id: '44yNN9ecwVA',
      title: 'Prêmio Sebrae Prefeito Empreendedor (PSPE) 28/06',
    },
    {
      id: 'rtSG1YVuzMo',
      title: 'Indicação Geográfica',
    },
    {
      id: 'Nbw4JpYrOOc',
      title: 'Promocional Empretec',
    },
    {
      id: '7rqBdu7ZlL4',
      title: 'Sebraetec',
    },
    {
      id: 'yakzkHVkrhI',
      title: 'Vídeo 50 anos',
    },
  ];

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title={
          <>
            Central de <br />
            Produção
          </>
        }
        description="Responsável pela cobertura, produção, edição e gestão de material audiovisual, além de criar, orientar e formatar estratégias para atendimento de demandas de criação interna."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="lg:pt-4 space-y-8 lg:space-y-16 !pb-0" colorTheme={colorTheme} watermark>
        <TitleArea>Audiovisual</TitleArea>

        <FlexibleBox colorTheme={colorTheme} title={<>Números de Produção</>} position={'center'} className="!mt-0 lg:!mt-[4rem]">
          <p>
            <strong className="campuni-bold">
              A produção de conteúdo audiovisual gera impacto positivo na comunicação e no relacionamento com os clientes.
            </strong>
          </p>
          <p>358 vídeos e animações</p>
          <p>113 lives</p>
          <p>14 transmissões ao vivo</p>
          <p>64 coberturas fotográficas </p>
          <p>Mais de uma ação audiovisual por dia em 2022</p>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Central Sebrae de Produção</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021-2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Foi constituído um novo modelo e uma nova estratégia de produção audiovisual para o Sebrae.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Augusto Togni</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Conheça mais sobre o trabalho da Central de Produção</strong>
              </p>

              {width < 1024 ? (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'http://youtube.com.br/sebrae'}>
                    Acesse os conteúdos
                  </LinkButton>
                </div>
              ) : (
                <LinkButton colorTheme={colorTheme} url={'http://youtube.com.br/sebrae'}>
                  Acesse os conteúdos
                </LinkButton>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Identidade visual da Central Sebrae de Produção</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Dez/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Para dar visibilidade e identidade ao projeto, uma identidade visual exclusiva foi desenvolvida para a Central
                Sebrae de Produção
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Augusto Togni</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width < 1024 ? (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={'https://drive.google.com/file/d/1xIpD8_N06KiZSI-DPNSsKkh_HjexmDH-/view'}
                  >
                    Faça o download do PDF
                  </LinkButton>
                </div>
              ) : (
                <LinkButton
                  colorTheme={colorTheme}
                  url={'https://drive.google.com/file/d/1xIpD8_N06KiZSI-DPNSsKkh_HjexmDH-/view'}
                >
                  Faça o download do PDF
                </LinkButton>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Projeto executivo dos estúdios e área de apoio audiovisual</>}
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jun/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Para modernizar a infraestrutura de produção audiovisual do Sebrae em sua sede, foram definidas as referências
                técnicas e elaborado e aprovado o projeto executivo dos estúdios eda área de apoio audiovisual
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Augusto Togni</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Equipe especializada em produção audiovisual</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Foram elaborados perfis profissionais, termo de referência e licitação de mão de obra para acontratação de
                especialistas para atuar diretamente nas produções.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Augusto Togni</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Contratação de produtora audiovisual</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Ago/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Para garantir diversidade de serviços e qualidade nas entregas da Central Sebrae de Produção, foram definidos os
                tipos de serviços e termo de referência e realizada uma licitação de técnica e preço para a contratação de uma
                produtora audiovisual com 39 tipos de serviços distintos.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Augusto Togni e Astrid Guimarães</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Mais 725 mil inscritos no YouTube</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A gestão do canal de comunicação audiovisual e a publicação de conteúdos periódicos comestratégias de
                impulsionamento de campanhas e gestão do canal gerou aproximadamente 700 mil inscritos no canal do Sebrae no
                YouTube.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Augusto Togni e Astrid Guimarães.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-8">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              <div className="w-box-full flex items-center justify-center">
                <img src={ImageYouTube} alt=" " />
              </div>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Sensibilização do Sistema Sebrae com o vídeo de 50 anos do Sebrae</>}
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid1"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jul/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Foi produzido um vídeo em comemoração aos 50 anos do Sebrae com a história da instituição.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Augusto Togni</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              <div className="w-box-full flex items-center justify-center pt-6">
                <IframeYT width="500px" id="c40_7PNu9kk" visible={vidVisible.vid1} />
              </div>
            </div>
          </div>
        </FlexibleBox>

        <TitleArea className="!pb-12 !pt-16">Design</TitleArea>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Fortalecimento da Marca com Criatividade</>}
          position={'center'}
          className="!mt-0 lg:!mt-[4rem]"
        >
          <p>
            <strong className="campuni-bold">
              Criação de soluções visuais diferenciadas, agregando valor à identidade da marca e ampliando o reconhecimento.
            </strong>
          </p>
          <p>33 identidades visuais</p>
          <p>38 apresentações</p>
          <p>23 análises de branding</p>
          <p>268 criações diversas</p>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Produção de Identidades visuais</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Ago/19 a out/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Foram criadas iniciativas por meio de identidades visuais, logotipos e selos para identificar,comunicar, trazer
                atributos, agregar valor e posicionar estrategicamente a marca do Sebrae, levando em conta o branding, o mercado
                em que atuam e a estratégia de divulgação decada uma.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>33 produtos, soluções, eventos e selos representados graficamente</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Taylor Carvalho</p>
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <div className="pt-[100px] lg:pt-[94px] pb-[32px] lg:pb-[28px] w-full relative bg-[#f1eeea]">
        <div className="central-producoes">
          <div className="flex justify-between">
            <div className="titles">
              <h2>Confira algumas produções de destaque</h2>
              <p>{videos[videoActive].title}</p>
            </div>

            <div className="videos lg:w-[499px] relative">
              <button onClick={() => swiperRef.current?.slidePrev()} className="btn-prev">
                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.459286 8.16504L6.11929 13.815C6.21225 13.9088 6.32285 13.9832 6.44471 14.0339C6.56657 14.0847 6.69727 14.1108 6.82929 14.1108C6.9613 14.1108 7.092 14.0847 7.21386 14.0339C7.33572 13.9832 7.44632 13.9088 7.53929 13.815C7.72554 13.6277 7.83008 13.3742 7.83008 13.11C7.83008 12.8459 7.72554 12.5924 7.53929 12.405L2.58929 7.40504L7.53929 2.45504C7.72554 2.26768 7.83008 2.01423 7.83008 1.75004C7.83008 1.48586 7.72554 1.2324 7.53929 1.04504C7.44667 0.950554 7.33623 0.875383 7.21435 0.823887C7.09248 0.77239 6.96159 0.745592 6.82929 0.745043C6.69698 0.745592 6.5661 0.77239 6.44422 0.823887C6.32235 0.875383 6.2119 0.950554 6.11929 1.04504L0.459286 6.69504C0.357781 6.78868 0.276772 6.90234 0.221365 7.02883C0.165957 7.15533 0.137353 7.29194 0.137353 7.43004C0.137353 7.56814 0.165957 7.70475 0.221365 7.83125C0.276772 7.95775 0.357781 8.0714 0.459286 8.16504Z"
                    fill="black"
                  />
                </svg>
              </button>

              <button onClick={() => swiperRef.current?.slideNext()} className="btn-next">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.54071 6.29004L1.88071 0.640037C1.78775 0.546308 1.67715 0.471914 1.55529 0.421145C1.43343 0.370377 1.30273 0.344238 1.17071 0.344238C1.0387 0.344238 0.907997 0.370377 0.786138 0.421145C0.664278 0.471914 0.553677 0.546308 0.460714 0.640037C0.274463 0.827399 0.169922 1.08085 0.169922 1.34504C0.169922 1.60922 0.274463 1.86267 0.460714 2.05004L5.41071 7.05004L0.460714 12C0.274463 12.1874 0.169922 12.4409 0.169922 12.705C0.169922 12.9692 0.274463 13.2227 0.460714 13.41C0.553329 13.5045 0.663774 13.5797 0.785649 13.6312C0.907525 13.6827 1.03841 13.7095 1.17071 13.71C1.30302 13.7095 1.4339 13.6827 1.55578 13.6312C1.67765 13.5797 1.7881 13.5045 1.88071 13.41L7.54071 7.76004C7.64222 7.66639 7.72323 7.55274 7.77864 7.42624C7.83404 7.29974 7.86265 7.16314 7.86265 7.02504C7.86265 6.88693 7.83404 6.75033 7.77864 6.62383C7.72323 6.49733 7.64222 6.38368 7.54071 6.29004Z"
                    fill="black"
                  />
                </svg>
              </button>

              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={pagination}
                onBeforeInit={swiper => {
                  swiperRef.current = swiper;
                }}
                modules={[Pagination, Navigation, FreeMode]}
                onSlideChange={a => setVideoActive(a.realIndex)}
              >
                {videos.map((video, index) => (
                  <SwiperSlide key={video.id}>
                    {({ isActive }) => (
                      <div className="item-video bg-rich-black w-[499px] h-[301px]">
                        <IframeYT width="100%" height="100%" id={video.id} visible={isActive} />
                      </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
