import React, { useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode, Swiper as SwiperType } from 'swiper';

import { useBasic } from 'contexts';
import { ContentBox, Hero, NucleosDeAtuacao } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.scss';
import ImageHero from 'assets/images/hero-projeto-especial.jpg';
import ImageLancamento from 'assets/images/projeto-especial/lancamento.jpg';
import ImageLive1 from 'assets/images/projeto-especial/live-1.jpg';
import ImageLive2 from 'assets/images/projeto-especial/live-2.jpg';
import ImageLive3 from 'assets/images/projeto-especial/live-3.jpg';
import ImageLive4 from 'assets/images/projeto-especial/live-4.jpg';
import ImageLive5 from 'assets/images/projeto-especial/live-5.jpg';
import ImageGaleria1 from 'assets/images/projeto-especial/galeria-1.png';
import ImageGaleria2 from 'assets/images/projeto-especial/galeria-2.png';
import ImageGaleria3 from 'assets/images/projeto-especial/galeria-3.png';
import ImageGaleria4 from 'assets/images/projeto-especial/galeria-4.png';
import ImageGaleria5 from 'assets/images/projeto-especial/galeria-5.png';
import ImageGaleria6 from 'assets/images/projeto-especial/galeria-6.png';
import ImageGaleria7 from 'assets/images/projeto-especial/galeria-7.png';

export default function ProjetoEspecialPage() {
  const { width } = useBasic();
  const colorTheme = '#FFB380';

  const [images, setImages] = useState<{ [key: string]: any }>({
    slot1: ImageGaleria1,
    slot2: ImageGaleria2,
    slot3: ImageGaleria3,
    slot4: ImageGaleria4,
    slot5: ImageGaleria5,
    slot6: ImageGaleria6,
    slot7: ImageGaleria7,
  });

  const changeGallery = (slot: string) => {
    setImages(prevstate => {
      return {
        ...prevstate,
        [`slot${slot}`]: prevstate.slot1,
        slot1: prevstate[`slot${slot}`],
      };
    });
  };

  const gallery: JSX.Element[] = [
    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('2')}
    >
      <img src={images.slot2} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,

    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('3')}
    >
      <img src={images.slot3} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,

    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('4')}
    >
      <img src={images.slot4} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,

    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('5')}
    >
      <img src={images.slot5} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,

    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('6')}
    >
      <img src={images.slot6} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,

    <div
      className="w-[150px] h-[100px] flex items-center justify-center rounded-md overflow-hidden cursor-pointer"
      onClick={() => changeGallery('7')}
    >
      <img src={images.slot7} alt=" " className="block w-[105%] h-[105%] object-cover" />
    </div>,
  ];

  const swiperRef = useRef<SwiperType>();

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="pagination-ico ' + className + '"></span>';
    },
  };

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title={
          <>
            Projeto <br />
            Especial
          </>
        }
        description="O Sebrae completou 50 anos em 2022. Foram realizadas inúmeras  ações com o objetivo de fazer a travessia entre o rico passado de conquistas, a atuação nos dias de hoje e as contribuições para o futuro."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="pt-[48px] !pb-[13px] lg:!px-[54px] text-rich-black" colorTheme={colorTheme} watermark>
        <h2 className="text-3xl lg:text-5xl font-campuni-bold font-normal block text-center mb-4">Projeto Sebrae 50+50</h2>
        <p className="text-lg lg:text-[28px] block max-w-[1138px] w-full mx-auto text-center font-campuni-regular font-normal leading-normal mb-4">
          Dentro do conceito "Criar o futuro é fazer história", a celebração ressaltou a importância estratégica dos pequenos
          negócios para o país e o papel do Sebrae como o principal parceiro dos empreendedores brasileiros. Foram mais de 30
          iniciativas propostas e implementadas pela UCOM, além do acompanhamento da implementação de mais de 100 ações pelas
          diretorias, CDN e demais unidades do Sebrae.
        </p>
        <p className="block text-center font-campuni-bold font-normal text-lg mb-8">
          Principais iniciativas 50+50 implementadas pela Ucom:
        </p>

        <div className="w-full bg-cultured rounded-md border-2 border-very-light-tangelo p-4 mb-10 lg:mb-14">
          <ol className="w-full space-y-4 font-campuni-normal text-lg" style={{ columns: width >= 1024 ? 2 : 1 }}>
            <li className="">1 - Criação do selo institucional 50+50 e brandbook da marca Sebrae</li>
            <li className="">2 - Hotsite Sebrae 50+50</li>
            <li className="">3 - Identidade visual (padronização nos canais de atendimento)</li>
            <li className="">4 - Projeto de comunicação visual nos espaços internos</li>
            <li className="">5 - Projeto “Quem faz e quem fez o Sebrae”</li>
            <li className="">6 - Produções para plataformas de streaming</li>
            <li className="">7 - Expedição Sebrae 50+50 - em realização</li>
            <li className="">8 - Minissérie: “Futuro é hoje” (seis episódios)</li>
            <li className="">9 - Documentário: “Sebrae 50 Anos”</li>
            <li className="">10 - Projeto “Jornalistas na Expedição Sebrae 50+50”</li>
            <li className="">11 - Prêmio Sebrae de Jornalismo - 8ª Edição</li>
            <li className="">12 - Projeto “Press trip”</li>
            <li className="">13 - Projeto jornalístico “Travessia da crise”</li>
            <li className="">14 - Série de Artigos “O futuro é agora”</li>
            <li className="">15 - Projeto “Empreendedores: geração de 72”</li>
            <li className="">16 - Projeto “Fotojornalismo: empreendedores pelo país”</li>
            <li className="">17 - Parcerias com veículos de comunicação</li>
            <li className="">18 - Lançamento da nova Agência Sebrae de Notícias (ASN)</li>
            <li className="">19 - Infográfico de marcos históricos</li>
            <li className="">20 - Infográfico “O futuro do empreendedorismo”</li>
            <li className="">21 - Inauguração da TV Sebrae: CANAL EMPREENDER</li>
            <li className="">22 - Patrocínio ao programa de TV PEGN</li>
            <li className="">23 - Campanha 50+50: público externo</li>
            <li className="">24 - Campanha 50+50: público interno</li>
            <li className="">25 - Projeto “Histórias de colaboradores”</li>
            <li className="">26 - Mural dos colaboradores</li>
            <li className="">27 - Campanha do Dia das MPE Associada ao 50+50</li>
            <li className="">28 - Pesquisa inspiracional sobre perfil do MEI</li>
            <li className="">29 - Projeto HQ “Reviravoltas” no Instagram do Sebrae</li>
            <li className="">30 - Evento de comemoração dos 50 anos (5/7)</li>
            <li className="">31 - Evento de encerramento do Projeto 50+50 - A realizar</li>
          </ol>
        </div>

        <div className="block w-full lg:px-[65px]">
          <div className="lg:flex justify-start">
            <div className="w-full lg:max-w-[860px] bg-cultured rounded-md border-2 border-very-light-tangelo p-4">
              <h3 className="text-[28px] leading-snug font-campuni-bold font-normal block text-center mb-8">
                Lançamento do Projeto Sebrae 50+50
              </h3>

              <div className="lg:flex items-center justify-between space-y-4 lg:space-y-0 lg:space-x-4">
                <img src={ImageLancamento} alt=" " className="w-full lg:max-w-[300px] block" />

                <div className="lg:pr-[9px] flex-1 font-campuni-regular font-normal text-lg">
                  <p>
                    A largada para os 50 anos do Sebrae aconteceu em 5 de julho de 2021 com o lançamento do Projeto Sebrae 50+50.
                    O evento contou com a apresentação do ator Dan Stulbach e foram distribuídas camisetas com a linha do passado,
                    presente e futuro aos colaboradores.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:flex justify-end pt-[40px] lg:pt-[66px]">
            <div className="w-full lg:max-w-[860px] bg-cultured rounded-md border-2 border-very-light-tangelo p-4">
              <h3 className="text-[28px] leading-snug font-campuni-bold font-normal block text-center mb-8">
                Live: apresentação do calendário Sebrae 50+50
              </h3>

              <div className="lg:flex items-center justify-between space-y-4 lg:space-y-0">
                <div className="projeto-especial-carousel relative">
                  <button onClick={() => swiperRef.current?.slidePrev()} className="btn-prev">
                    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.459286 8.16504L6.11929 13.815C6.21225 13.9088 6.32285 13.9832 6.44471 14.0339C6.56657 14.0847 6.69727 14.1108 6.82929 14.1108C6.9613 14.1108 7.092 14.0847 7.21386 14.0339C7.33572 13.9832 7.44632 13.9088 7.53929 13.815C7.72554 13.6277 7.83008 13.3742 7.83008 13.11C7.83008 12.8459 7.72554 12.5924 7.53929 12.405L2.58929 7.40504L7.53929 2.45504C7.72554 2.26768 7.83008 2.01423 7.83008 1.75004C7.83008 1.48586 7.72554 1.2324 7.53929 1.04504C7.44667 0.950554 7.33623 0.875383 7.21435 0.823887C7.09248 0.77239 6.96159 0.745592 6.82929 0.745043C6.69698 0.745592 6.5661 0.77239 6.44422 0.823887C6.32235 0.875383 6.2119 0.950554 6.11929 1.04504L0.459286 6.69504C0.357781 6.78868 0.276772 6.90234 0.221365 7.02883C0.165957 7.15533 0.137353 7.29194 0.137353 7.43004C0.137353 7.56814 0.165957 7.70475 0.221365 7.83125C0.276772 7.95775 0.357781 8.0714 0.459286 8.16504Z"
                        fill="black"
                      />
                    </svg>
                  </button>

                  <button onClick={() => swiperRef.current?.slideNext()} className="btn-next">
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.54071 6.29004L1.88071 0.640037C1.78775 0.546308 1.67715 0.471914 1.55529 0.421145C1.43343 0.370377 1.30273 0.344238 1.17071 0.344238C1.0387 0.344238 0.907997 0.370377 0.786138 0.421145C0.664278 0.471914 0.553677 0.546308 0.460714 0.640037C0.274463 0.827399 0.169922 1.08085 0.169922 1.34504C0.169922 1.60922 0.274463 1.86267 0.460714 2.05004L5.41071 7.05004L0.460714 12C0.274463 12.1874 0.169922 12.4409 0.169922 12.705C0.169922 12.9692 0.274463 13.2227 0.460714 13.41C0.553329 13.5045 0.663774 13.5797 0.785649 13.6312C0.907525 13.6827 1.03841 13.7095 1.17071 13.71C1.30302 13.7095 1.4339 13.6827 1.55578 13.6312C1.67765 13.5797 1.7881 13.5045 1.88071 13.41L7.54071 7.76004C7.64222 7.66639 7.72323 7.55274 7.77864 7.42624C7.83404 7.29974 7.86265 7.16314 7.86265 7.02504C7.86265 6.88693 7.83404 6.75033 7.77864 6.62383C7.72323 6.49733 7.64222 6.38368 7.54071 6.29004Z"
                        fill="black"
                      />
                    </svg>
                  </button>

                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={pagination}
                    onBeforeInit={swiper => {
                      swiperRef.current = swiper;
                    }}
                    modules={[Pagination, Navigation, FreeMode]}
                  >
                    <SwiperSlide>
                      <div className="item-image">
                        <img src={ImageLive1} alt=" " />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="item-image">
                        <img src={ImageLive2} alt=" " />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="item-image">
                        <img src={ImageLive3} alt=" " />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="item-image">
                        <img src={ImageLive4} alt=" " />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="item-image">
                        <img src={ImageLive5} alt=" " />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="lg:w-[432px] font-campuni-regular font-normal text-lg">
                  <p>
                    No dia 15 de dezembro de 2021, o Sebrae apresentou o calendário de atividades do cinquentenário, com mais de
                    130 iniciativas para o ano de 2022, como atividades e ações por todo o país com a participação de todo o
                    Sistema Sebrae.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-[56px] lg:pt-[130px] pb-[100px] lg:pb-0">
          <h3 className="font-campuni-bold font-normal block text-center text-[28px] leading-none text-rich-black mb-4 lg:mb-2">
            Celebração dos 50 anos do Sebrae
          </h3>
          <p className="font-campuni-normal font-normal block text-center text-lg text-rich-black mb-4 lg:mb-[40px]">
            Confira as fotos do evento de comemoração do cinquentenário do Sebrae.
          </p>

          <div className="w-full lg:w-[700px] lg:h-[465px] flex items-center justify-center rounded-md overflow-hidden mx-auto mb-14 lg:mb-8 aspect-16-9">
            <img src={images.slot1} alt=" " className="block w-[105%] h-[105%] object-cover" />
          </div>

          <div className="projeto-especial-gallery flex items-center justify-center lg:justify-between w-full max-w-[1060px] mx-auto">
            {width >= 1024 ? (
              gallery.map((item, index) => <React.Fragment key={index + 2 * 2}>{item}</React.Fragment>)
            ) : (
              <Swiper slidesPerView={2} spaceBetween={20} pagination={pagination} modules={[Pagination, FreeMode]}>
                {gallery.map((item, index) => (
                  <SwiperSlide key={index + 2 * 2}>{item}</SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
