import { HiArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import './style.scss';

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <section id="not-found" className="flex flex-col items-center justify-center">
      <img src={''} alt="Error 404" />

      <h2>Página não encontrada!</h2>

      <button className="back flex flex-col items-center justify-center" onClick={() => navigate(-1)}>
        <span className="ico">
          <HiArrowLeft />
        </span>
        Voltar
      </button>
    </section>
  );
}
