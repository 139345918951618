import { useState, useEffect } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import './style.scss';

export interface FlexibleBoxInterface {
  colorTheme: string;
  title: string | React.ReactNode;
  position: 'start' | 'center' | 'end';
  className?: string;
  onUpdateState?: (state: boolean, id: string) => void;
  id?: string;
  children: React.ReactNode;
}

export function FlexibleBox({ colorTheme, title, position, className, onUpdateState, id, children }: FlexibleBoxInterface) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (typeof onUpdateState === 'function' && typeof id === 'string') {
      onUpdateState(opened, id);
    }
  }, [id, opened, onUpdateState]);

  return (
    <div
      className={`flexible-block w-full flex ${className || ''}`}
      style={
        {
          '--color-theme': colorTheme || '',
          justifyContent: position === 'start' || position === 'end' ? `flex-${position}` : position,
        } as React.CSSProperties
      }
    >
      <div className={`content ${opened ? 'opened' : 'closed'}`}>
        <div
          className="head flex items-center justify-between text-international-klein-blue p-4 lg:p-6 space-x-4 lg:space-x-12 cursor-pointer"
          onClick={() => setOpened(prevstate => !prevstate)}
        >
          <svg className="graph" width="114" height="40" viewBox="0 0 114 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 27.842L104.088 27.842L113 1.33384L23.5694 1.33385L15 27.842Z" stroke={colorTheme} />
            <path
              opacity="0.4"
              d="M1 38.6661L90.0878 38.6661L99 12.1579L9.5694 12.1579L1 38.6661Z"
              fill={colorTheme}
              stroke={colorTheme}
            />
          </svg>
          <p className="flex-1 font-campuni-bold font-normal text-[18px] lg:text-[28px] leading-[1.5em] lg:leading-[32px] tracking-[-0.0428em]">
            {title}
          </p>
          {opened ? (
            <AiOutlineMinus className="icon text-[30px] lg:text-[38px]" />
          ) : (
            <AiOutlinePlus className="icon text-[30px] lg:text-[38px]" />
          )}
        </div>
        <div className="body p-4 lg:p-6 text-base lg:text-lg leading-normal text-rich-black font-campuni-regular">{children}</div>
      </div>
    </div>
  );
}
