import { useCallback, useState } from 'react';

import { useBasic } from 'contexts';
import { Anchor, ContentBox, FlexibleBox, Hero, IframeYT, LinkButton, NucleosDeAtuacao, TitleArea } from 'components';

import './style.scss';
import ImageHero from 'assets/images/hero-promocao.jpg';
import ImageFeira from 'assets/images/promocao/feira-empreendedor.jpg';

export default function PromocaoPage() {
  const { width } = useBasic();
  const colorTheme = '#84F4BC';

  const [vidVisible, setVidVisible] = useState({
    vid1: false,
    vid2: false,
    vid3: false,
    vid4: false,
    vid5: false,
    vid6: false,
    vid7: false,
    vid8: false,
    vid9: false,
    vid10: false,
    vid11: false,
  });

  const onUpdateState = useCallback((state: boolean, id: string) => {
    setVidVisible(prevstate => ({ ...prevstate, [id]: state }));
  }, []);

  return (
    <>
      <Hero
        colorTheme={colorTheme}
        title="Promoção"
        description="Realiza gestão das agências de publicidade, execução de campanhas e ações de live marketing que proporcionam a interlocução viva, sensorial e diferenciada entre a marca e os clientes, trazendo uma compreensão única de seus produtos, serviços e propósitos."
        image={<img src={ImageHero} alt=" " />}
      />

      <ContentBox className="lg:pt-4 space-y-8 lg:space-y-16" colorTheme={colorTheme} watermark>
        <TitleArea>Publicidade</TitleArea>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Resultados das campanhas <br />
              2019 a 2022
            </>
          }
          position={'center'}
          className="!mt-0 lg:!mt-[4rem]"
        >
          <p>39 campanhas principais</p>
          <p>+ de 109 ações publicitárias</p>
          <p>+ de 1.496.103.350 impactos</p>
          <p>+ de 716.521.594 video views de campanhas na web</p>
          <p>+ de 5.397.351 novos usuários no Portal Sebrae provenientes de campanhas</p>
          <p>+ de 29.486.043 engajamentos nas campanhas na web</p>
          <p>+ de 5.264.025.611 impressões de peças na internet</p>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Cursos online</>}
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid1"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Fev/2023</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Campanha para incentivar o empreendedores a se capacitar usando os cursos online do Sebrae</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior Alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>+ 341,2 milhões de impressões/impactos</p>
              <p>+ 107,6 milhões de visualizações do vídeo</p>
              <p>+ 30,6 milhões de engajamentos</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Eduardo Duarte</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="0UjAr4PRPYE" visible={vidVisible.vid1} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Compre do Pequeno 2022</>}
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid2"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>03 a 23/Dez</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                O fim de ano chegou, as festas vem ai e na hora de escolher onde comprar os presentes, a ceia, o encontro de fim
                de ano, priorize e escolha oos pequenos negócios. Além de ser perto de casa, eles movimentam o bairro, a cidade, a
                comunidade e ajudam na economia do País.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                30 milhões de impactos (TV) <br />
                160 milhões de impressões das peças <br />
                mais de 400.000 acessos ao site do Sebrae proveniente da campanha
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte, Fernando Bandeira e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="wevZoz2HFe8" visible={vidVisible.vid2} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Dia da MPE 2022</>}
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid3"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out/2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha de celebração em homenagem ao Dia da Micro e Pequena empresa.
                <br />
                Fizemos uma releitura musical de um clássico do artista Seu Jorge.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>
                Celebrar o dia 5 de outubro e lembrar que o Sebrae está sempre ao lado dos empresários de micro e pequenos
                negócios
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>76 milhões de visualizações do vídeo, e mais de 150 milhões de impressões nas peças de internet</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Eduardo Duarte</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="TDKKs28to3M" visible={vidVisible.vid3} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              Independência
            </>
          }
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid4"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Set/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha para comemorar a data de 200 anos da independência do Brasil e fazer uma analógia com a coragem dos
                empreendedores brasileiros.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>2,6 milhões de visualizações do vídeo em apenas 1 dia de veiculação.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte, Fernando Bandeira e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="WiAzN_F4TOI" visible={vidVisible.vid4} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Vote na MPE"
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Ago-Set/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A campanha pretendia estimular a sociedade, os empreendedores e os candidatos a apoiar os pequenos negócios e suas
                pautas, mostrando a importância e a ligação do empreendedorismo com os assuntos mais relevantes para o país, como
                economia, educação, saúde, segurança e emprego.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                1,3 milhão de visualizações do vídeo da campanha <br />
                109 mil sessões provenientes de 98 mil novos usuários
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Fernando Bandeira</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://guiadocandidato2022.sebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://guiadocandidato2022.sebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              50 anos do Sebrae
            </>
          }
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jul/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A campanha marcou a celebração dos 50 anos do Sebrae, colocando o protagonismo nos donos de pequenos negócios. Os
                empreendedores foram evidenciados como a força motriz, mostrando a importância desse setor para a economia e para
                o desenvolvimento do Brasil. O Sebrae foi retratado como a instituição que sempre esteve ao lado dos
                empreendedores das micros e pequenas empresas, dando apoio ontem, hoje e sempre. A campanha contou com um grande
                mix de comunicação, sendo veiculada em TV aberta, fechada, mídia exterior e internet.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                +506 milhões de impactos <br />
                +268 milhoes de visualizações de vídeos <br />
                1,3 milhão de acesso ao hotsite <br />1 milhão de engajamentos.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte, Fernando Bandeira e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/sebrae50mais50'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/sebrae50mais50'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Prêmio Sebrae <br />
              Mulher de Negócios
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jun-Jul/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Divulgação do Prêmio Sebrae Mulher de Negócios, cuja proposta era inspirar mulheres por meio de histórias de
                outras empreendedoras.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                2.926 inscrições, que supera em quase 200% a meta inicial <br />
                264 mil visualizações da página do Prêmio no Portal Sebrae
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/premiomulherdenegocios'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/premiomulherdenegocios'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Lançamento da Nova Agência Sebrae de Notícias</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jun/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A Agência Sebrae de Notícias chegou para fortalecer ainda mais a cultura empreendedora no Brasil, e por isso foi
                feita a campanha de lançamento: "A nova ASN vem mais forte, em mais formatos e com conteúdos mais relevantes para
                a vida das pessoas".
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>581 mil sessões provenientes de 528 mil usuários. Desses, 90% são novos.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Fernando Bandeira e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://agenciasebrae.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Campanha "Menino no meio da ponte"</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jan/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                No início de 2022, foi veiculada uma campanha para reconhecer o esforço dos empreendedores brasileiros, que
                precisam de muita coragem para enfrentar obstáculos, desafios e seguir em frente. A repercussão da mensagem e do
                formato escolhido para contar a história do Bento foi enorme.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                41 milhões de visualizações do video <br />
                + de 100 mil novos seguidores no canal do YTB do Sebrae em 12 dias de campanha
                <br />
                2.824 dowloads do livro digital
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte e Fernando Bandeira</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/coragem'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/coragem'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha final de ano <br />
              2021
            </>
          }
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid5"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Dez/21-Jan/22</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A campanha passa uma mensagem reflexiva de fim de ano e mostra que, mesmo com tantos desafios, o Sebrae sempre
                esteve presente na vida do empreendedor. E, para os novos dias que se iniciam, o empreendedor pode continuar
                contando com esse apoio.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>89 milhões de pessoas foram impactadas pela campanha</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Fernando Bandeira</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="AdAn-6qUGzA" visible={vidVisible.vid5} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              App Sebrae
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out-Dez/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                A campanha do App Sebrae, veiculada em 2021, tinha o objetivo de tornar o aplicativo conhecido e estimular o
                download. A campanha reforçou o apoio do Sebrae aos pequenos negócios e a praticidade entregue pelo app.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Alcançar, até o final de dezembro, um milhão de downloads</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>+ de 1 milhão de downloads do App Sebrae</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/aplicativosebrae'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/aplicativosebrae'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              Eficiência energética
            </>
          }
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Essa campanha digital comunicou, para os donos de micros e pequenas empresas, que o Sebrae pode ajudá-los a
                melhorar o desempenho financeiro do seu negócio por meio de um consumo energético mais eficaz. As peças trouxeram
                exemplos práticos de como as micros e pequenas empresas podem se tornar mais competitivas economizando energia.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>+ de 70 mil acessos ao conteúdo de eficiência energética no Portal Sebrae</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Fernando Bandeira</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/eficienciaenergetica'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.sebrae.com.br/sites/PortalSebrae/eficienciaenergetica'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Feira do Empreendedor <br />
              2021
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Set-Out/21</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha criada para estimular a inscrição e participação na Feira do Empreendedor 2021. O evento foi realizado
                pela primeira vez em formato 100% digital.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                110.213 inscrições <br />
                57.710 participantes <br />
                151.665 atendimentos <br />
                238 reuniões com estimativa de R$ 5.579.264,45 gerados
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos focais</strong>
              </p>
              <p>Eduardo Duarte e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width < 1024 ? (
                <div className="w-box-full flex items-center justify-center">
                  <img src={ImageFeira} alt="" className="w-full block" />
                </div>
              ) : (
                <img src={ImageFeira} alt="" className="w-[350px]" />
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Retomada/Protocolos"
            </>
          }
          position={'end'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jul-Ago/20</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha para auxiliar pequenas empresas a se estruturarem para o retorno às atividades de forma segura, sem expor
                seus clientes e funcionários a riscos. "Para que esse recomeço aconteça em segurança, é importante que os
                empreendedores saibam como agir."
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                1,9 milhão de novos usuários no Portal Sebrae <br />
                4,3 milhões de visualização dos vídeos nas redes sociais <br />
                100 mil downloads de conteúdos técnicos no Portal
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Marcelo Porlan</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/sites/PortalSebrae/protocolosderetomada'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://sebrae.com.br/sites/PortalSebrae/protocolosderetomada'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Sebrae ao seu lado"
            </>
          }
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid6"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Abr-Mai/20</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha para ajudar os empreendedores a superar os desafios durante a pandemia da covid-19 e gerar tráfego para
                as soluções existentes no Portal Sebrae.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                20,7 milhões de pessoas impactadas na TV. <br />
                + de 62 milhões de visualizações de vídeos no Youtube <br />3 milhoes de sessões no Portal do Sebrae
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="1jgMs_hmQxc" visible={vidVisible.vid6} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Compre do Pequeno"
            </>
          }
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid7"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Mar-Abr/20</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Com a crise causada pela covid-19, o varejo sentiu um impacto econômico. Por isso a campanha “Compre do pequeno”
                foi criada, valorizando o comércio, os serviços e as empresas locais naquele momento delicado. A campanha tinha o
                objetivo de incentivar, apoiar, gerar solidariedade e cuidado com o pequeno empreendedor.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                190 mil sessões no Portal do Sebrae, que geraram 130 mil novos usuários <br />
                54,l 4 milhões de alcance nas redes sociais.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Marcelo Porlan</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="wevZoz2HFe8" visible={vidVisible.vid7} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Grandes números"
            </>
          }
          position={'start'}
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out-Nov/19</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Com o objetivo de mostrar aos empreendedores as vantagens de ter um negócio formalizado, foi criada uma campanha
                para informar que, por meio da cooperação entre empresas e empreendedores, é possível fortalecer os pequenos
                negócios para torná-los mais competitivos.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                442 mil visitas na página da campanha <br />+ de 1 milhão de visualizaçoes do vídeo.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Marcelo Porlan</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              <div
                className={`${
                  width < 1024 ? 'w-box-full' : ''
                } flex flex-col lg:flex-row items-center justify-center lg:justify-start space-y-6 lg:space-y-0 lg:space-x-4`}
              >
                <LinkButton colorTheme={colorTheme} url={'https://www.instagram.com/p/B4ciEjhjTGB/?img_index=1'}>
                  Acesse o vídeo
                </LinkButton>

                <LinkButton
                  colorTheme={colorTheme}
                  url={'https://www.uol/economia/conteudo-de-marca/sebrae-a-forca-do-pequeno-negocio.htm#vencendo-na-vida'}
                >
                  Acesse o publieditorial
                </LinkButton>
              </div>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={
            <>
              Campanha <br />
              "Dia das MPE"
            </>
          }
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid8"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Out/19</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Uma data tão especial precisa de celebração. O Dia das Micro e Pequenas Empresas ganhou uma campanha especial e
                exclusiva.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                35 milhões de pessoas alcançadas. <br />
                14 milhôes de visualizações de vídeo.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais Ilustrativos</strong>
              </p>
              <Anchor href="https://www.youtube.com/watch?v=7qBcP1f4vCU">https://www.youtube.com/watch?v=7qBcP1f4vCU</Anchor>
              <IframeYT className="mt-4" id="7qBcP1f4vCU" visible={vidVisible.vid8} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Campanha "ESC - Empresa Simples de Crédito"</>}
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid9"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Set/19</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Campanha para informar que qualquer pessoa que tem um capital parado pode ser ESC e assim extrapolar o número
                previsto de 1.000 ESCs previstas até 2020.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Despertar nas pessoas o interesse de se tornar uma ESC</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                78,5 milhões de pessoas impactadas na TV <br />
                2,2 milhões de pessoas impactadas no rádio <br />
                13,7 milhões de visualizações do vídeo da campanha.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Henrique Nabuco e Eduardo Duarte</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais Ilustrativos</strong>
              </p>
              <Anchor href="https://www.youtube.com/watch?v=wevZoz2HFe8">https://www.youtube.com/watch?v=wevZoz2HFe8</Anchor>
              <IframeYT className="mt-4" id="24uMYI9aZJ0" visible={vidVisible.vid9} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Campanha Institucional de 10 anos de MEI</>}
          position={'end'}
          onUpdateState={onUpdateState}
          id="vid10"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Ago/19</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                "Quando a gente fala que nenhum MEI está sozinho, é porque cada empreendedor pode contar com o apoio do Sebrae
                para capacitar e transformar a coragem individual em um grande negócio para todos."
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Campanha de alcance para celebrar o marco de 10 anos do MEI.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                100 milhões de pessoas alcançadas na TV. <br />
                56 milhões de visualizações do vídeo da campanha.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="ofdBxR4Mvwg" visible={vidVisible.vid10} />
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox
          colorTheme={colorTheme}
          title={<>Campanha Institucional "A Força do Empreendedor brasileiro" </>}
          position={'start'}
          onUpdateState={onUpdateState}
          id="vid11"
        >
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>Jul-Set/19</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Na época, o país estava passando por um momento delicado na sua economia, aguardando uma reforma muito importante
                para que voltasse a crescer, se desenvolver, gerar emprego e renda. Nesse contexto, o empreendedor precisava estar
                preparado. Com essa campanha, o Sebrae se colocou ao lado e à disposição do empreendedor brasileiro para fazer com
                que ele avançasse e seguisse em frente.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Objetivo</strong>
              </p>
              <p>Maior de alcance de pessoas.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Resultado</strong>
              </p>
              <p>
                83 milhões de pessoas impactadas na TV. <br />
                23 milhões de visualizações do vídeo da campanha na internet.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Eduardo Duarte e Henrique Nabuco</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-4">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>
              <IframeYT id="FcZjvw1AfpE" visible={vidVisible.vid11} />
            </div>
          </div>
        </FlexibleBox>

        <TitleArea className="!pb-12 !pt-16">Live Marketing</TitleArea>

        <FlexibleBox colorTheme={colorTheme} title={<>Campus Party</>} position={'end'} className="!mt-0 lg:!mt-[4rem]">
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Ações de experiência com a marca no Espaço Like a Boss na Campus Party Brasil, em 2021, e nas Campus Party Brasil,
                em Goiás e Brasília, em 2022.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Rodrigo d'Orey</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://brasil.campus-party.org/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://brasil.campus-party.org/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Bett Brasil</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção das soluções, programas e iniciativas da unidade por meio de ações de experiência com a marca na Bett
                Educar. Em 2022, além de estar presente na feira, o Sebrae promoveu a cerimônia de premiação do PSEE, ciclo
                2021-2022.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Elaine Novetti</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://brasil.bettshow.com/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://brasil.bettshow.com/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Fispal Food Service</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção das iniciativas, produtos, soluções e parcerias da unidade para o setor de alimentação fora do lar. Na
                ocasião, foram divulgados os e-books sobre eficiência energética e UP Digital Marketing para o segmento e as ações
                do Brasil Mais.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Rodrigo d'Orey</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.fispalfoodservice.com.br/pt/home.html'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.fispalfoodservice.com.br/pt/home.html'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Rally dos Sertões</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção dos pequenos negócios que oferecem serviços essenciais em cidades e/ou municípios em que o Rally dos
                Sertões passou nesses anos. Em 2022, das 15 etapas, 13 foram comemorativas aos 30 anos do Rally dos Sertões, e uma
                carreta com ações de experiência com a marca foi utilizada. Além disso, ocorreu um concurso gastronômico entre
                restaurantes de 15 municípios, resultando em um guia de locais indicados pelo Sebrae das UFs.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Pontos Focais</strong>
              </p>
              <p>Jassana Chagas e Rodrigo d'Orey</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://sertoes.com'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://sertoes.com'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Beauty Fair</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019 e 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção das iniciativas, produtos, soluções e parcerias da unidade para o setor de beleza e estética. <br />
                Formalização MEI BELEZA - META: 1.000 novos CNPJs <br />
                Beauty Tech - META: 200 e-books ESG enviados
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.beautyfair.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.beautyfair.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Projeto Rota das Águas</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção de iniciativas, produtos, soluções e parcerias da unidade para alimentos e bebidas. Ações de ativação de
                marca em Água Branca, Caicó, Juazeiro, Parnaíba, Petrolina, Propriá e São Roque.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Elaine Novetti</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton
                    colorTheme={colorTheme}
                    url={
                      'https://revistadeagronegocios.com.br/governo-federal-lanca-jornada-das-aguas-para-fomentar-desenvolvimento'
                    }
                  >
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton
                    colorTheme={colorTheme}
                    url={
                      'https://revistadeagronegocios.com.br/governo-federal-lanca-jornada-das-aguas-para-fomentar-desenvolvimento'
                    }
                  >
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Startup Summit</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção do Sebrae Like a Boss por meio de ações de experiência com a marca e divulgação de outras iniciativas
                voltadas para o ecossistema de Inovação (Catalisa, UP Digital, Sebraetec). Na ocasião, houve a final do Desafio
                Sebrae Like a Boss em parceria com os institutos Inova+ e Get In The Ring.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Rodrigo d'Orey</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.startupsummit.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.startupsummit.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Empretec Realiza</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promoção de um evento que faz parte da jornada do empreteco, voltado aos ex-alunos do Seminário Empretec. Além
                disso, houve uma cerimônia de retrospectiva do Empretec com Zeca Camargo, valorizando empretecos de outros anos.
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Mercopar</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 - 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Ativação da marca Sebrae e sensibilização para temas estratégicos, como energia, transformação digital e
                produtividade, no espaço do live marketing e criação de identidade visual utilizada nos estandes que foram
                disponibilizados para pequenos negócios (60).
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://mercopar.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://mercopar.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Prêmio Sebrae de Jornalismo</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 - 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>
                Promover uma experiência de relacionamento dos jornalistas com Prêmio Sebrae de Jornalismo, mobilizando e
                incentivando inscrições e aproximando o público de jornalistas das pautas do Sebrae (empreendedorismo e pequenos
                negócios).
              </p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://premiosebraejornalismo.com.br'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://premiosebraejornalismo.com.br'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Anufood Brazil</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019-2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Ação Empório Sebrae - Do Brasil à mesa com exposição de produtos.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.anufoodbrazil.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.anufoodbrazil.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Bio Brazil Fair</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2019-2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Ação Do Brasil à mesa com exposição de produtos de origem.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://biobrazilfair.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://biobrazilfair.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>MeliXP</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Ação se joga no online.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Débora Fonseca</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.melixp.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.melixp.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Febratex</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Espaço Sebrae Fashion - Performance e Sustentabilidade.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Débora Fonseca</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://febratex.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://febratex.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Estétika</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Jassana Chagas</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.neventum.com.br/feiras/esttika-brasil'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.neventum.com.br/feiras/esttika-brasil'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>GoRN!</>} position={'start'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Ação Sebrae Travessia.</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Débora Fonseca</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://www.natal.rn.gov.br/news/post2/37755'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://www.natal.rn.gov.br/news/post2/37755'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>

        <FlexibleBox colorTheme={colorTheme} title={<>Latam Retail Show</>} position={'end'}>
          <div className="space-y-6">
            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Período</strong>
              </p>
              <p>2021 - 2022</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Síntese do assunto</strong>
              </p>
              <p>Ação Sebrae Digital</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Ponto Focal</strong>
              </p>
              <p>Rodrigo d'Orey</p>
            </div>

            <div>
              <p className="leading-none text-lg lg:text-[22px] text-international-klein-blue block mb-2">
                <strong className="font-campuni-bold font-normal">Materiais ilustrativos</strong>
              </p>

              {width >= 1024 ? (
                <>
                  <LinkButton colorTheme={colorTheme} url={'https://latamretailshow.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </>
              ) : (
                <div className="w-box-full flex items-center justify-center">
                  <LinkButton colorTheme={colorTheme} url={'https://latamretailshow.com.br/'}>
                    Acesse o site
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </FlexibleBox>
      </ContentBox>

      <NucleosDeAtuacao colorTheme={colorTheme} />
    </>
  );
}
