import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Footer, Header } from 'components';
import { useBasic } from 'contexts';
import { ChildrenProp } from 'types';

export default function Layout({ children }: ChildrenProp) {
  const location = useLocation();
  const { showFooter, showHeader, customClass } = useBasic();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <>
      <div
        id="container"
        className={`${typeof customClass === 'string' && customClass.trim().length ? `${customClass.trim()}` : ''}`}
      >
        {showHeader && <Header />}

        <main>{children}</main>

        {showFooter && <Footer />}
      </div>
    </>
  );
}
